export const { VITE_APP_NAME, VITE_LOGOUT_URL: LOGOUT_URL } = import.meta.env

export const DATE_FORMAT = 'YYYY.MM.DD'
export const NEWS_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FULL_MONTH_FORMAT = 'MMMM D, YYYY'

export const LANGUAGES = {
  ko: 'ko',
  en: 'en',
} as const

// default config for pagination
export const DEFAULT_PAGE = 1
export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_TOTAL_PAGES = 1

export const SEARCH_PARAM = 'keyword'

// based on stylesheet global scss
export const headerHeights = {
  MOBILE: 50,
  TABLET: 60,
  DESKTOP: 80,
}

export const apiVersion = {
  v1: '/api/v1',
}
