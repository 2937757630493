import { useEffect, useRef } from 'react'

import { RelatedNewsDetailItem } from '@/pages/support/components/news-detail/related-news-detail/related-news-detail-item'
import { RelatedNewsDTO } from '@/services/news'

import classes from './related-news.module.scss'

type RelatedNewsProps = {
  previousNews?: RelatedNewsDTO
  nextNews?: RelatedNewsDTO
}

export const RelatedNews = ({ previousNews, nextNews }: RelatedNewsProps) => {
  const previousRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!previousRef.current || !nextRef.current) return

    if (previousRef.current.clientWidth > nextRef.current.clientWidth) {
      nextRef.current.style.minWidth = previousRef.current.clientWidth + 'px'
    } else {
      previousRef.current.style.minWidth = nextRef.current.clientWidth + 'px'
    }
  }, [previousNews, nextNews])

  return (
    <div className={classes.container} data-testid="data-test-id-related-news-detail">
      {previousNews && <RelatedNewsDetailItem {...previousNews} type="PREV" ref={previousRef} />}
      {nextNews && <RelatedNewsDetailItem {...nextNews} type="NEXT" ref={nextRef} />}
    </div>
  )
}
