import { useTranslation } from 'react-i18next'

import { AlertIcon } from '@/components/icons/alert-icon'
import { i18nLanguage } from '@/i18n'

import styles from './not-found.module.scss'

export const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <AlertIcon className={styles.icon} />
      <h1 className={styles.info}>{t(i18nLanguage.error.notFound.info)}</h1>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: t(i18nLanguage.error.notFound.description) }}
      />
    </div>
  )
}
