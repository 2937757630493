export function ArrowBackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M7.80469 10.7988L3.00469 5.99883L7.80469 1.19883"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
