import { apiVersion } from '@/configs/common'
import { EPermissionView } from '@/services/documents/documents.type'
import { Locale } from '@/types/locales'

const prefix = '/documents'

export const documentEndpoints = {
  getNestedTree: () => `${prefix}/nested-tree`,
  markdownSource: () => `${apiVersion.v1}${prefix}/markdown/index.html`,
}

export const documentsKeys = {
  all: ['document'] as const,
  nestedTree: (lang: Locale, permision: EPermissionView) =>
    [...documentsKeys.all, 'nestedTree', lang, permision] as const,
}
