import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { documentAPI } from '@/services/documents/documents.api'
import { documentsKeys } from '@/services/documents/documents.config'

import { TGetNestedTreeParams, TNestedTreeResponse } from './documents.type'

const useGetDocumentsNestedTree = <Data = TNestedTreeResponse>(
  params: TGetNestedTreeParams,
  options?: Omit<UseQueryOptions<TNestedTreeResponse, unknown, Data>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: documentsKeys.nestedTree(params.langType, params.permissionView),
    queryFn: () => documentAPI.getNestedTree(params),
    ...options,
  })

export { useGetDocumentsNestedTree }
