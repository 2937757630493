export function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14648 10.4521C4.14648 6.96584 6.97267 4.13965 10.4589 4.13965C13.9452 4.13965 16.7714 6.96584 16.7714 10.4521C16.7714 13.9384 13.9452 16.7646 10.4589 16.7646C6.97267 16.7646 4.14648 13.9384 4.14648 10.4521ZM10.4589 2.13965C5.8681 2.13965 2.14648 5.86127 2.14648 10.4521C2.14648 15.043 5.8681 18.7646 10.4589 18.7646C12.394 18.7646 14.1746 18.1034 15.5872 16.9946L20.4394 21.8468L21.8536 20.4325L17.0014 15.5804C18.1102 14.1678 18.7714 12.3872 18.7714 10.4521C18.7714 5.86127 15.0498 2.13965 10.4589 2.13965Z"
        fill="currentColor"
      />
    </svg>
  )
}
