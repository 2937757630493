import apiService from '@/configs/axios'

import { announcementEndpoints } from './announcement.config'
import { AnnouncementDetailParams, AnnouncementParams } from './announcement.dto'
import { AnnouncementDetailResponse, AnnouncementResponse } from './announcement.type'

export const announcementAPI = {
  getList(params: AnnouncementParams): Promise<AnnouncementResponse> {
    return apiService.get(announcementEndpoints.getList(), { params })
  },

  getDetail({ id, ...params }: AnnouncementDetailParams): Promise<AnnouncementDetailResponse> {
    return apiService.get(announcementEndpoints.getDetail(id), { params })
  },
}
