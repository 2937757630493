import { ENV } from '@/configs/env'
import { TGetNewsListParams, TNewsParams } from '@/services/news/news.type'
import { Locale } from '@/types/locales'

// hard code, changing code after receiving detail requirements.
export const newListParamsWithUseCaseCategory = {
  categoryId: ENV.USE_CASE_CATEGORY_ID,
  page: 1,
  limit: 3,
}

export const newsEndpoints = {
  getList: () => `/news`,
  getLatest: () => `/news/latest`,
  getFixed: () => `/news/fixed`,
  getById: (id: string) => `/news/${id}`,
}

export const newsKeys = {
  all: ['news'] as const,
  mainNewsList: (lang: Locale) => [...newsKeys.all, 'mainNewsList', lang] as const,
  newsHeroList: (lang: Locale) => [...newsKeys.all, 'newsHeroList', lang] as const,
  newsById: (id: string, lang: Locale) => [...newsKeys.all, 'newsById', id, lang] as const,
  newsList: (params: TGetNewsListParams) => [...newsKeys.all, 'newsList', params] as const,
  mainNewsWithParams: (params: TNewsParams) => [newsEndpoints.getLatest(), params] as const,
  newsByIdClient: (lang: Locale) => [...newsKeys.all, 'newsByIdClient', lang] as const,
}

export enum PRODUCT_TYPE {
  ENTIRE = 'entire',
  DEVELOPERS = 'developers',
  WEBSITE = 'website',
}
