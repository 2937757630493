import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { Button } from '@/components/button'
import { OverviewIcon } from '@/components/icons'
import Skeleton from '@/components/skeleton'
import { HeaderDesktopMenuItem } from '@/layout/header/menu-desktop/menu-item'
import { HeaderMenuItemLv1 } from '@/layout/types'
import { useHeaderContext } from '@/providers/header-provider'

import classes from './menu-list.module.scss'

type HeaderDesktopMenuListProps = {
  menuData: HeaderMenuItemLv1
  isOpen: boolean
  isLoading?: boolean
}

export const HeaderDesktopMenuList = ({
  menuData,
  isOpen,
  isLoading = false,
}: HeaderDesktopMenuListProps) => {
  const navigate = useNavigate()
  const { actions } = useHeaderContext()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { label, hasIcon, listLabel, serviceCategories, link: listLink } = menuData

  const hasServiceCategories = serviceCategories && serviceCategories.length > 0
  const listChildrenLabel = listLabel ?? label

  const handleRedirect = (link?: string) => {
    if (link) {
      navigate(`/${language}/${link}`)
      actions.resetMenu()
    }
  }

  return (
    <div className={clsx(classes['header-desktop-menu-list'], { [classes['show']]: isOpen })}>
      <Button
        data-testid="list-service-category-label"
        className={clsx(classes['list-service-category-label'], {
          [classes['redirect-link']]: !!listLink,
        })}
        onClick={handleRedirect.bind(null, listLink)}
      >
        <div className={clsx(classes['overview-icon'], { [classes['show']]: hasIcon })}>
          <OverviewIcon />
        </div>
        {t(listChildrenLabel)}
      </Button>

      {hasIcon && (
        <div
          data-testid="list-service-category-separate"
          className={classes['list-service-category-separate']}
        />
      )}

      {isLoading &&
        Array.from({ length: 3 }).map((_, idx) => (
          <Skeleton key={idx} className={classes['header-menu-item-skeleton']} />
        ))}

      {hasServiceCategories &&
        serviceCategories.map((item) => {
          const hasServices = item.services && item.services.length > 0

          return hasServices ? (
            <HeaderDesktopMenuItem
              key={item.id}
              serviceCategory={item}
              handleRedirect={handleRedirect}
            />
          ) : (
            <Button
              data-testid="header-service-category-item"
              key={item.id}
              className={classes['service-category-item']}
              onClick={() => handleRedirect(item.link)}
            >
              <span className={classes['item-label']}>{t(item.label)}</span>
            </Button>
          )
        })}
    </div>
  )
}
