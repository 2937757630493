import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { t } from 'i18next'

import { locales } from '@/configs/locales'
import { Locale } from '@/types/locales'

import { flatKeyAndValueOfLocaleEn } from '../i18n'

export const useLocale = (): Locale => {
  const { i18n } = useTranslation()

  const language = i18n.language

  if (isSupportedLocale(language)) {
    return language
  }
  return locales.KO
}

function isSupportedLocale(locale: string): locale is Locale {
  return Object.values(locales).includes(locale as Locale)
}

function useBuildT() {
  const { i18n } = useTranslation()
  const buildT = useCallback(
    (key: string) => {
      return t(key, flatKeyAndValueOfLocaleEn[key])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language],
  )

  return buildT
}

export default useBuildT
