export function ChevronDownIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <mask
        id="mask0_25_10085"
        style={{ maskType: 'alpha' }}
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z" transform="matrix(0 1 1 0 0 0)"></path>
      </mask>
      <g mask="url(#mask0_25_10085)">
        <path
          fill="currentColor"
          d="M7.577 8.783L3.794 5 2.186 6.609 7.576 12l5.392-5.391L11.36 5 7.577 8.783z"
        ></path>
      </g>
    </svg>
  )
}
