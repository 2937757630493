export const DocsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon_Docs">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08594 2.19995C2.08594 1.92381 2.3098 1.69995 2.58594 1.69995H14.0859C14.3621 1.69995 14.5859 1.92381 14.5859 2.19995V16.2C14.5859 16.4761 14.3621 16.7 14.0859 16.7H2.58594C2.3098 16.7 2.08594 16.4761 2.08594 16.2V2.19995ZM4.16797 4.36401H12.168V5.40568H4.16797V4.36401ZM12.168 7.19995H4.16797V8.24162H12.168V7.19995ZM4.16797 10.032H8.7513V11.0736H4.16797V10.032ZM5.00391 18.1192C5.00391 18.0272 5.07853 17.9526 5.17057 17.9526H15.5052C15.6893 17.9526 15.8385 17.8033 15.8385 17.6192V4.78459C15.8385 4.69254 15.9132 4.61792 16.0052 4.61792H17.0039C17.28 4.61792 17.5039 4.84178 17.5039 5.11792V19.1179C17.5039 19.3941 17.28 19.6179 17.0039 19.6179H5.50391C5.22776 19.6179 5.00391 19.3941 5.00391 19.1179V18.1192Z"
        fill="black"
      />
    </g>
  </svg>
)
