import { SVGProps } from 'react'

export function CloseMenuIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5151 20.7347L3.31512 4.53473L4.80005 3.0498L21 19.2498L19.5151 20.7347Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.48488 20.7347L20.6849 4.53473L19.2 3.0498L2.99995 19.2498L4.48488 20.7347Z"
        fill="currentColor"
      />
    </svg>
  )
}
