import { useEffect } from 'react'

import clsx from 'clsx'

import '@/assets/styles/editor.scss'

interface RenderEditorProps {
  data?: string
}
const RenderEditor = ({ data = '' }: RenderEditorProps) => {
  useEffect(() => {
    const listCodeElement = document.querySelectorAll('.editor-code')
    listCodeElement.forEach((codeElement) => {
      if (!codeElement.querySelector("div[data-right-corner='true']")) {
        const rightCorner = document.createElement('div')
        rightCorner.setAttribute('data-right-corner', 'true')
        rightCorner.classList.add('right-corner')

        const copyButton = document.createElement('button')
        copyButton.classList.add('menu-item')
        copyButton.innerHTML = 'Copy'
        copyButton.onclick = () => {
          let textContent = codeElement.innerHTML || ''
          if (textContent) {
            // Replace <br> tags with \n
            textContent = textContent.replace(/<br\s*\/?>/gi, '\n')
            // Replace block-level elements with \n
            textContent = textContent.replace(/<\/(div|p|li|h[1-6])>/gi, '\n')
            // Remove any remaining HTML tags
            textContent = textContent.replace(/<[^>]+>/g, '')
            // Remove the specific string 'javascriptCopy'
            textContent = textContent.replace('javascriptCopy', '')
            navigator.clipboard.writeText(textContent)
          }
        }

        const language = codeElement.getAttribute('data-language')
        const languageElement = document.createElement('span')
        languageElement.innerHTML = language || ''
        languageElement.style.textTransform = 'capitalize'

        rightCorner.appendChild(languageElement)
        rightCorner.appendChild(copyButton)
        codeElement.appendChild(rightCorner)
      }
    })
  }, [data])
  return <div className={clsx('editor-container')} dangerouslySetInnerHTML={{ __html: data }} />
}

export default RenderEditor
