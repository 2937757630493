import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { Button } from '@/components/button'
import { LoginIcon, LogoutIcon, SearchIcon } from '@/components/icons'
import { SEARCH_PARAM } from '@/configs/common'
import { ROUTES } from '@/configs/paths'
import { i18nLanguage } from '@/i18n'
import { SearchBarHeader } from '@/layout/header/search-bar'
import { useHeaderContext } from '@/providers/header-provider'
import { authAPIs } from '@/services/auth/auth.api'
import { useGetMyInfo } from '@/services/auth/auth.hook'

import classes from './header-control.module.scss'

const i18n = i18nLanguage.header

export const HeaderControl = () => {
  const navigate = useNavigate()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const {
    actions,
    state: { isSearchBarOpen },
  } = useHeaderContext()

  const { data: userResponse, isFetching } = useGetMyInfo()

  const isValidUser = userResponse?.data

  const handleSubmitSearch = (value: string) => {
    navigate({
      pathname: `/${language}/${ROUTES.SEARCH}`,
      search: `${SEARCH_PARAM}=${encodeURIComponent(value)}`,
    })
  }

  const handleLogin = () => {
    authAPIs.redirectLogin()
  }

  const handleLogout = () => {
    authAPIs.redirectLogout()
  }

  return (
    <div
      className={clsx(classes['header-control'], { [classes['search-bar-open']]: isSearchBarOpen })}
    >
      <Button
        data-testid="header-control-search-btn"
        className={clsx(classes['header-btn'], classes['search-btn'])}
        onClick={actions.openSearchBar}
      >
        <SearchIcon />
      </Button>
      <SearchBarHeader
        showSearchIcon
        onSubmit={handleSubmitSearch}
        placeholder={t(i18n.searchPlaceholder)}
      />
      <div data-testid="header-control-divider" className={classes['divider']} />
      {isValidUser ? (
        <Button
          data-testid="header-control-btn"
          className={clsx(classes['header-btn'], classes['login-btn'])}
          onClick={handleLogout}
          disabled={isFetching}
        >
          <LogoutIcon />
          {t(i18n.logout)}
        </Button>
      ) : (
        <Button
          data-testid="header-control-btn"
          className={clsx(classes['header-btn'], classes['login-btn'])}
          onClick={handleLogin}
          disabled={isFetching}
        >
          <LoginIcon />
          {t(i18n.login)}
        </Button>
      )}
    </div>
  )
}
