import { useLayoutEffect, useState } from 'react'

export const breakpoints = {
  sm: 360,
  md: 692,
  lg: 1025,
  xl: 1367,
}

type MediaQueryType = keyof typeof breakpoints

export const mediaQueryBreakpoints = {
  up(query: MediaQueryType) {
    return `(min-width: ${breakpoints[query]}px)`
  },
  down(query: MediaQueryType) {
    return `(max-width: ${breakpoints[query]}px)`
  },
}

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false)

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(query)
    const updateMatches = () => {
      setMatches(mediaQuery.matches)
    }

    // Initial check
    updateMatches()

    // Listen for changes in the media query
    const mediaQueryListener = () => {
      updateMatches()
    }
    mediaQuery.addEventListener('change', mediaQueryListener)

    // Clean up listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', mediaQueryListener)
    }
  }, [query])

  return matches
}

export default useMediaQuery
