import { useRef, useState } from 'react'

import {
  arrow,
  autoUpdate,
  hide,
  offset,
  safePolygon,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'

export const useFloatingHeaderMenu = ({
  spacing,
}: {
  spacing: {
    top: number
    left: number
    bottom: number
  }
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)
  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: isOpen,
    placement: 'right',
    onOpenChange: setIsOpen,
    strategy: 'absolute',
    middleware: [
      offset(spacing.left),
      hide(),
      shift({
        padding: {
          top: spacing.top,
          bottom: spacing.bottom,
        },
      }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {
    delay: { open: 0.25 },
    handleClose: safePolygon({
      blockPointerEvents: true,
    }),
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  const arrowTopPosition = middlewareData.arrow?.y ? `${middlewareData.arrow.y}px` : undefined

  return {
    getReferenceProps,
    getFloatingProps,
    refs,
    floatingStyles,
    arrowTopPosition,
    context,
    isOpen,
    arrowRef,
    setIsOpen,
  }
}
