import { PropsWithChildren } from 'react'

import classes from './body.module.scss'

function Body({ children }: PropsWithChildren) {
  return (
    <main className={classes.wrapper} data-testid="app">
      {children}
    </main>
  )
}

export default Body
