import { useEffect, useRef } from 'react'

type UseEventListener<T extends keyof (DocumentEventMap | WindowEventMap)> = {
  eventType: T
  handler: (event: DocumentEventMap[T] | WindowEventMap[T]) => void
  isEnabled?: boolean
  target?: EventTarget | null | (() => EventTarget | null)
}

function useEventListener<T extends keyof (DocumentEventMap | WindowEventMap)>({
  eventType,
  handler,
  isEnabled = true,
  target,
}: UseEventListener<T>) {
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const node = (typeof target === 'function' ? target() : target) ?? document
    if (!isEnabled || !node) return

    const internalHandler: typeof handler = (event) => handlerRef.current(event)

    node.addEventListener(eventType, internalHandler as EventListener)

    return () => node.removeEventListener(eventType, internalHandler as EventListener)
  }, [eventType, isEnabled, target])
}

export default useEventListener
