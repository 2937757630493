import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'

import clsx from 'clsx'

import { Button } from '@/components/button'
import { SearchIcon } from '@/components/icons'
import { ROUTES } from '@/configs/paths'
import { i18nLanguage } from '@/i18n'
import { getSearchKeywordFromURL } from '@/layout/utils'
import { useHeaderContext } from '@/providers/header-provider'
import { removeLanguageFromUrl } from '@/utils/language-url'

import classes from './search-bar.module.scss'

const i18n = i18nLanguage.common.button

const MINIMUM_DELAY_SUBMIT = 50

type SearchBarHeaderProps = {
  showSearchIcon?: boolean
  placeholder?: string
  onSubmit: (value: string) => void
}

export const SearchBarHeader = ({
  onSubmit,
  placeholder = '',
  showSearchIcon = false,
}: SearchBarHeaderProps) => {
  const { t } = useTranslation()
  const {
    actions,
    state: { isSearchBarOpen },
  } = useHeaderContext()
  const { search, pathname } = useLocation()

  const currentRoute = removeLanguageFromUrl(pathname)
  const isSearchPage = matchPath(ROUTES.SEARCH, currentRoute)

  const [searchValue, setSearchValue] = useState(() =>
    isSearchPage ? getSearchKeywordFromURL(search) : '',
  )
  const searchBarRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleSubmit = () => {
    onSubmit(searchValue)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setTimeout(() => {
        searchInputRef.current?.blur()
        handleSubmit()
      }, MINIMUM_DELAY_SUBMIT)
    }
  }

  useEffect(() => {
    if (isSearchBarOpen && searchInputRef.current) searchInputRef.current.focus()
  }, [isSearchBarOpen])

  useEffect(() => {
    if (searchValue) actions.openSearchBar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isSearchPage) setSearchValue('')
  }, [isSearchPage])

  return (
    <div
      data-testid="header-search-bar"
      ref={searchBarRef}
      className={clsx(classes['search-bar-header'], { [classes['show']]: isSearchBarOpen })}
    >
      <div
        data-testid="header-search-input-wrapper"
        className={classes['header-search-input-wrapper']}
      >
        {showSearchIcon && (
          <Button
            data-testid="header-search-input-icon"
            onClick={handleSubmit}
            className={classes['search-icon']}
          >
            <SearchIcon />
          </Button>
        )}
        <input
          type="search"
          enterKeyHint="search"
          data-testid="header-search-bar-input"
          ref={searchInputRef}
          className={classes['header-search-input']}
          value={searchValue}
          onChange={handleChange}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
        />
      </div>
      <Button
        data-testid="header-search-cancel-btn"
        onClick={actions.closeSearchBar}
        className={classes['btn-close-search-bar']}
      >
        {t(i18n.cancel)}
      </Button>
    </div>
  )
}
