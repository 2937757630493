import apiService from '@/configs/axios'
import { apiVersion } from '@/configs/common'
import { authEndpoints } from '@/services/auth/auth.config'
import { MyInfoResponse } from '@/services/auth/auth.dto'
import { RefreshResponse } from '@/services/auth/auth.type'

export const authAPIs = {
  redirectLogin() {
    window.location.href = `${apiVersion.v1}${authEndpoints.login()}`
  },
  redirectLogout() {
    window.location.href = authEndpoints.logout()
  },
  myInfo(): Promise<MyInfoResponse> {
    return apiService.get(authEndpoints.myInfo)
  },
  refreshToken(): Promise<RefreshResponse> {
    return apiService.get(authEndpoints.refreshToken)
  },
}
