export const common = {
  news: 'News',
  docs: 'Docs',
  en: 'EN',
  ko: 'KO',
  footer: '© Nexon Korea Corporation All Rights Reserved.',
  privacyPolicy: '개인정보처리방침',
  button: {
    cancel: '취소',
    back: 'Back',
  },
  home: '홈',
  support: '지원',
  announcements: '공지사항',
  navigation: {
    previous: '이전',
    next: '다음',
    backToList: '목록으로',
  },
}
