import { forwardRef } from 'react'

import { RouterLink } from '@/components/router-link'
import { ROUTES } from '@/configs/paths'
import useBuildT from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n'
import { RelatedNewsDTO } from '@/services/news'

import classes from './related-news.module.scss'

export type RelatedNewsDetailItemProps = {
  type: 'PREV' | 'NEXT'
} & RelatedNewsDTO

export const RelatedNewsDetailItem = forwardRef<HTMLDivElement, RelatedNewsDetailItemProps>(
  function ({ type, category, title, id }, ref) {
    const t = useBuildT()

    const typeString =
      type === 'PREV'
        ? t(i18nLanguage.common.navigation.previous)
        : t(i18nLanguage.common.navigation.next)

    return (
      <RouterLink href={`/${ROUTES.NEWS_LIST}/${id}`}>
        <div className={classes.wrapper} data-testid="data-test-id-related-news-detail-item">
          <p className={classes.type}>{typeString}</p>
          <p ref={ref} data-testid="data-test-id-category" className={classes.category}>
            {category.name}
          </p>
          <h1 data-testid="data-test-id-title" className={classes.title}>
            {title}
          </h1>
        </div>
      </RouterLink>
    )
  },
)

RelatedNewsDetailItem.displayName = 'RelatedNewsDetailItem'
