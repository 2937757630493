export const main = {
  heroSection: {
    title: 'Make a Difference\nwith GameScale Developers',
    description:
      'We provide comprehensive documentation introducing the GameScale service,\nincluding the necessary APIs and development processes for feature implementation.',
    viewDoc: 'View Documentation',
  },
  contents: {
    learnMore: 'Learn More',
    viewAll: 'View All',
    service: {
      header: 'GameScale Services',
      gameScaleQuote: 'From development to live service, the easiest way to scale up the quality.',
    },
    documentation: {
      header: 'Documentation',
      doc1Title: 'Getting Started',
      doc1Desc: 'Essential documents to promptly begin your development process.',
      doc2Title: 'Service Integration',
      doc2Desc: 'Comprehensive guides to implement your desired service functionalities.',
      doc3Title: 'API Reference',
      doc3Desc: 'All the necessary information for using APIs with GameScale.',
    },
    update: {
      header: 'Updates',
      changeLog: 'Release Notes',
      announcements: 'Announcements',
    },
    news: {
      header: 'News',
      changeLog: 'Changelog',
      announcements: 'Announcements',
    },
  },
}
