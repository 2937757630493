import { useRouteError } from 'react-router-dom'

import { HttpStatusCode, isAxiosError } from 'axios'

import Header from '@/layout/header'
import { ForbiddenPage } from '@/pages/error/forbidden'
import { NotFoundPage } from '@/pages/error/not-found'
import { UnauthorizedPage } from '@/pages/error/unauthorize'

import classes from './error.module.scss'

const ErrorPage = () => {
  const error = useRouteError()

  return (
    <div className={classes.errorPage}>
      <Header isErrorPage />
      {(() => {
        if (isAxiosError(error)) {
          switch (error.response?.status) {
            case HttpStatusCode.NotFound:
              return <NotFoundPage />
            case HttpStatusCode.Forbidden:
              return <ForbiddenPage />
            case HttpStatusCode.Unauthorized:
              return <UnauthorizedPage />

            default:
              return <NotFoundPage />
          }
        }
        return <NotFoundPage />
      })()}
    </div>
  )
}

export default ErrorPage
