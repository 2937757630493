import { SVGProps } from 'react'

export const CircleAlert = ({ className, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_4462_21451)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1673 10.5002C19.1673 15.5628 15.0633 19.6668 10.0007 19.6668C4.93804 19.6668 0.833984 15.5628 0.833984 10.5002C0.833984 5.43755 4.93804 1.3335 10.0007 1.3335C15.0633 1.3335 19.1673 5.43755 19.1673 10.5002ZM10.8423 14.6668H9.16732V13.0002H10.8423V14.6668ZM10.834 6.3335V11.3335H9.16732V6.3335H10.834Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4462_21451">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
