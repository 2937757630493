import { useEffect, useState } from 'react'
import { ResizableBox } from 'react-resizable'
import { useNavigate, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import { EErrorCode } from '@/enums'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import DocumentDirectory from '@/pages/documents/components/document-directory/document-directory'
import RenderMarkdown from '@/pages/documents/components/render-markdown/render-markdown'
import SidebarMobile from '@/pages/documents/components/sidebar-mobile/sidebar-mobile'
import TableOfContent from '@/pages/documents/components/table-of-content/table-of-content'
import ToastNotFound from '@/pages/documents/components/toast-not-found/toast-not-found'
import {
  DEFAULT_WIDTH_SIDEBAR,
  MAX_WIDTH_SIDEBAR,
  MIN_WIDTH_SIDEBAR,
} from '@/pages/documents/configs'
import { GETTING_STARTED_DOCUMENT_PATH } from '@/pages/documents/constants'
import { useDocumentsContext } from '@/pages/documents/context'
import MarkdownLayout from '@/pages/documents/layouts/markdown-layout/markdown-layout'
import { ForbiddenPage } from '@/pages/error/forbidden'
import { UnauthorizedPage } from '@/pages/error/unauthorize'
import { useGetMyInfo } from '@/services/auth/auth.hook'

import BreadcrumbsDocument from '..'
import styles from './render-document-page.module.scss'

const RenderDocumentPage = () => {
  const navigate = useNavigate()
  const isDesktopLarge = useMediaQuery(mediaQueryBreakpoints.up('xl'))
  const isDesktop = useMediaQuery(mediaQueryBreakpoints.up('lg'))
  const [isResizing, setIsResizing] = useState(false)
  const { errorCode, setErrorCode, isMarkdownInitialized } = useDocumentsContext()
  const [searchParams] = useSearchParams()
  const path = searchParams.get('path')
  const { data: userInfo } = useGetMyInfo()

  useEffect(() => {
    const handleWindowScroll = () => {
      const footer = document.querySelector<HTMLElement>('footer')
      const sidebar = document.querySelector<HTMLDivElement>('.react-resizable')
      const tableOfContent = document.querySelector<HTMLDivElement>('#table-of-content-main')
      if (!footer || !sidebar) return

      // height of the footer view in the window
      let spacing = footer.getBoundingClientRect().top - window.innerHeight
      if (spacing < 0) {
        spacing = Math.abs(spacing)
        sidebar.style.maxHeight = `calc(100vh - var(--header-height) - ${spacing}px)`
        if (tableOfContent) {
          tableOfContent.style.maxHeight = `calc(100vh - var(--header-height) - ${spacing}px)`
        }
      } else {
        sidebar.style.maxHeight = 'calc(100vh - var(--header-height))'
        if (tableOfContent) {
          tableOfContent.style.maxHeight = 'calc(100vh - var(--header-height))'
        }
      }
    }
    window.addEventListener('scroll', handleWindowScroll)
    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [])

  useEffect(() => {
    if (!path && userInfo) {
      if (userInfo.data) setErrorCode(null)
      if (isMarkdownInitialized) {
        const newParams = new URLSearchParams(searchParams.toString())
        newParams.set('path', GETTING_STARTED_DOCUMENT_PATH)
        const newUrl = `${window.location.pathname}?${decodeURIComponent(newParams.toString())}`
        navigate(newUrl, { replace: true })
      }
    }
  }, [path, searchParams, isMarkdownInitialized, userInfo])

  return (
    <>
      {!errorCode ? (
        <div
          className={clsx(styles['wrapper'], {
            [styles['wrapper-small']]: !isDesktop,
          })}
        >
          {isDesktop ? (
            <ResizableBox
              minConstraints={[MIN_WIDTH_SIDEBAR, Infinity]}
              maxConstraints={[MAX_WIDTH_SIDEBAR, Infinity]}
              width={DEFAULT_WIDTH_SIDEBAR}
              axis="x"
              handle={<div id="barResize" className={styles['bar-resize']} />}
              className={styles['resizable-box']}
              onResizeStart={() => {
                setIsResizing(true)
              }}
              onResizeStop={() => {
                setIsResizing(false)
              }}
            >
              <DocumentDirectory />
            </ResizableBox>
          ) : (
            <SidebarMobile />
          )}
          <div className={styles['content-wrapper']} id="markdown-content-wrapper">
            <ToastNotFound />
            <MarkdownLayout>
              {isDesktop && <BreadcrumbsDocument />}
              <div className={styles['main-content']} id="main-content">
                <RenderMarkdown isResizing={isResizing} />
                {isDesktopLarge && <TableOfContent />}
              </div>
            </MarkdownLayout>
          </div>
        </div>
      ) : (
        <>
          {errorCode === EErrorCode.UNAUTHORIZED && <UnauthorizedPage />}
          {errorCode === EErrorCode.FORBIDDEN && <ForbiddenPage />}
        </>
      )}
    </>
  )
}

export default RenderDocumentPage
