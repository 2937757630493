import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { ChevronRightIcon } from '@/components/icons'
import { i18nLanguage } from '@/i18n'
import { useDocumentsContext } from '@/pages/documents/context'

import classes from './breadcrumb-document.module.scss'

type BreadcrumbsDocumentProps = {
  className?: string
  size?: 's' | 'm'
}

const BreadcrumbsDocument = ({ className, size = 's' }: BreadcrumbsDocumentProps) => {
  const { breadcrumbs, tableOfContentData } = useDocumentsContext()
  const { t } = useTranslation()
  const breadcrumbsData = [
    t(i18nLanguage.common.home),
    t(i18nLanguage.header.nav.document),
    ...breadcrumbs.slice(1).map((item) => item.title),
  ]

  return (
    <ul
      data-testid="breadcrumbs"
      className={clsx(classes['breadcrumbs'], classes[`size_${size}`], className)}
      data-no-table-of-content={!tableOfContentData.length}
    >
      {breadcrumbsData.map((breadcrumb, i) => (
        <li key={i} className={classes['breadcrumb']}>
          {i === breadcrumbsData.length - 1 ? (
            <span className={classes['breadcrumbLast']}>{breadcrumb}</span>
          ) : (
            <>
              <span>{breadcrumb}</span>
              <ChevronRightIcon />
            </>
          )}
        </li>
      ))}
    </ul>
  )
}

export default BreadcrumbsDocument
