import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import { CircleAlert } from '@/components/icons'
import { useLocale } from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n'
import { useDocumentsContext } from '@/pages/documents/context'
import { sendPathToIframe } from '@/pages/documents/utils'

import styles from './toast-not-found.module.scss'

const ToastNotFound: FC = () => {
  const i18nDocuments = i18nLanguage.documents
  const lang = useLocale()
  const [searchParams] = useSearchParams()
  const path = searchParams.get('path')

  const { t } = useTranslation()

  const { markdownContentError, setMarkdownContentError, iframeRef, setIsMarkdownChanged } =
    useDocumentsContext()

  useEffect(() => {
    setMarkdownContentError({ error: false, type: null })
  }, [lang])

  useEffect(() => {
    if (!iframeRef.current || !path) return
    if (markdownContentError.error && markdownContentError.type === 'notFound' && lang === 'en') {
      sendPathToIframe(iframeRef.current, path, 'ko', setIsMarkdownChanged)
    }
  }, [markdownContentError.error])

  return (
    <>
      {markdownContentError.type && (
        <div className={styles['toast-container']}>
          <div className={clsx(styles['text-notification'], styles[lang])}>
            <CircleAlert className={styles['icon-alert']} />
            <span className={clsx(styles['text-content'])}>
              {t(i18nDocuments[markdownContentError.type as keyof typeof i18nDocuments])}
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default ToastNotFound
