import { matchPath } from 'react-router-dom'

import queryString from 'query-string'

import { ROUTES } from '@/configs/paths'
import { layoutThemes, layoutThemesByRoute, routesHideHeaderSpace } from '@/layout/configs'
import { HeaderMenuItemLv2, HeaderMenuItemLv3 } from '@/layout/types'
import { ServiceCategoryResponse, ServiceMinimal } from '@/services/service'
import { PathRoutesType } from '@/types/common'
import { removeLanguageFromUrl } from '@/utils/language-url'

export const getLayoutThemes = (pathname: string) => {
  const currentRoute = removeLanguageFromUrl(pathname)

  if (currentRoute === ROUTES.MAIN) return layoutThemesByRoute[ROUTES.MAIN]

  const routes = Object.values(ROUTES)
  const filteredRoute = routes.find((route) => matchPath(route, currentRoute) !== null)

  if (filteredRoute) return layoutThemesByRoute[filteredRoute as PathRoutesType]

  return layoutThemes.DARK
}

const transformServiceList = (serviceList: ServiceMinimal[]): HeaderMenuItemLv3[] => {
  return serviceList.map((service) => ({
    id: service.id.toString(),
    label: service.name,
    link: `${ROUTES.SERVICE_OVERVIEW}/${service.id}`,
  }))
}

export const transformHeaderData = (response: ServiceCategoryResponse): HeaderMenuItemLv2[] => {
  const { serviceCategories } = response.data

  const result = serviceCategories.map((serviceCategory) => ({
    label: serviceCategory.name,
    id: serviceCategory.id.toString(),
    services: serviceCategory.services ? transformServiceList(serviceCategory.services) : [],
  }))

  return result
}

export const isHideHeaderSpace = (pathname: string) => {
  const currentRoute = removeLanguageFromUrl(pathname)

  const [ROUTES_MAIN, ...routes] = routesHideHeaderSpace
  if (currentRoute === ROUTES_MAIN) return true

  return routes.some((route) => matchPath(route, currentRoute) !== null)
}

export const getSearchKeywordFromURL = (search: string) => {
  return (queryString.parse(search)?.keyword as string | null) ?? ''
}
