import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useParams } from 'react-router-dom'

import { ROUTES } from '@/configs/paths'
import { i18nLanguage } from '@/i18n'
import {
  NewsDetailHeroSection,
  RelatedNews,
  RenderEditor,
  ShowListButton,
} from '@/pages/support/components/news-detail'
import { TNewsDetailResponse } from '@/services/news'

import classes from './news-detail.module.scss'

function NewsDetailPage() {
  const { lang } = useParams()

  const { t, i18n } = useTranslation()

  const meta = useMemo(() => i18nLanguage.meta.newsDetail, [i18nLanguage])

  const loaderData = useLoaderData() as TNewsDetailResponse['data']
  const newsDetail = loaderData.detail

  const title = newsDetail.news.title

  return (
    <>
      <Helmet prioritizeSeoTags htmlAttributes={{ lang: i18n.language }}>
        {title && (
          <>
            <title>{t(meta.title, { detail: title })}</title>
            <meta property="og:title" content={t(meta.title, { detail: title })} />
          </>
        )}
        <meta name="description" content={t(meta.description)} />
        <meta property="og:description" content={t(meta.ogDescription)} />
        <meta property="og:image" content="https://developers.gamescale.io/gsd-open-graph.png" />
        <meta
          property="og:image:secure_url"
          content="https://developers.gamescale.io/gsd-open-graph.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
      </Helmet>
      <NewsDetailHeroSection data={newsDetail.news} />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.detail}>
            <RenderEditor data={newsDetail.news.detail} />
          </div>
          <RelatedNews
            previousNews={newsDetail?.navigation.previousNews}
            nextNews={newsDetail?.navigation.nextNews}
          />
          <ShowListButton pathname={`/${lang}/${ROUTES.NEWS_LIST}`} />
        </div>
      </div>
    </>
  )
}

export default NewsDetailPage
