import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { CloseIcon, DocsIcon } from '@/components/icons'
import { ArrowDown } from '@/components/icons/arrow-down'
import { i18nLanguage } from '@/i18n'
import { DocumentDirectory } from '@/pages/documents/components/document-directory/document-directory'
import { useDocumentsContext } from '@/pages/documents/context'

import classes from './sidebar-mobile.module.scss'

const SidebarMobile = () => {
  const i18nDocuments = i18nLanguage.documents
  const { t } = useTranslation()
  const { openSidebarMobile, setOpenSidebarMobile } = useDocumentsContext()
  const handleOpen = () => {
    setOpenSidebarMobile((open) => !open)
  }
  return (
    <div className={classes['dropdown-menu']} id="markdown-sidebar-mobile">
      <button onClick={handleOpen} className={classes.button}>
        <div className={classes.left}>
          <DocsIcon />
          <p className={classes.header}>{t(i18nDocuments.document)}</p>
        </div>
        <div className={classes.right}>{openSidebarMobile ? <CloseIcon /> : <ArrowDown />}</div>
      </button>
      <div className={clsx({ [classes['menu-open']]: openSidebarMobile }, classes['menu-close'])}>
        <DocumentDirectory />
      </div>
    </div>
  )
}

export default SidebarMobile
