import { LANGUAGES } from '@/configs/common'
import { ROUTES } from '@/configs/paths'
import { i18nLanguage } from '@/i18n'
import { HeaderMenuItemLv1, HeaderMenuItemLv2 } from '@/layout/types'

export const layoutThemes = {
  DARK: 'dark',
  LIGHT: 'light',
  LIGHT_BLUE: 'light-blue',
  LIGHT_GRAY: 'light-gray',
} as const

export const layoutThemesByRoute = {
  [ROUTES.MAIN]: layoutThemes.DARK,
  [ROUTES.NOTICE_LIST]: layoutThemes.LIGHT,
  [ROUTES.NOTICE_DETAIL]: layoutThemes.LIGHT,
  [ROUTES.NEWS_LIST]: layoutThemes.LIGHT_GRAY,
  [ROUTES.NEWS_DETAIL]: layoutThemes.LIGHT,
  [ROUTES.SERVICE_OVERVIEW]: layoutThemes.LIGHT,
  [ROUTES.SERVICE_DETAIL]: layoutThemes.LIGHT_BLUE,
  [ROUTES.DOCUMENT]: layoutThemes.LIGHT,
  [ROUTES.SEARCH]: layoutThemes.LIGHT,
}

export const routesHideHeaderSpace = [ROUTES.MAIN, ROUTES.SERVICE_DETAIL] as const

const i18nHeaderNav = i18nLanguage.header.nav
const i18nCommon = i18nLanguage.common

export const headerNavIdList = {
  service: 'service',
  support: 'support',
  document: 'document',
  news: 'support-news',
  announcements: 'support-announcements',
} as const

export const supportCategoryHeaderNav: HeaderMenuItemLv2[] = [
  {
    id: headerNavIdList.announcements,
    label: i18nHeaderNav.announcements,
    link: ROUTES.NOTICE_LIST,
  },
  {
    id: headerNavIdList.news,
    label: i18nHeaderNav.news,
    link: ROUTES.NEWS_LIST,
  },
]

export const supportHeaderNav: HeaderMenuItemLv1 = {
  id: headerNavIdList.support,
  label: i18nHeaderNav.support,
  serviceCategories: supportCategoryHeaderNav,
}

export const serviceHeaderNav: HeaderMenuItemLv1 = {
  id: headerNavIdList.service,
  label: i18nHeaderNav.service,
  link: ROUTES.SERVICE_OVERVIEW,
  listLabel: i18nHeaderNav.serviceOverview,
  hasIcon: true,
}

export const headerNavStatic: HeaderMenuItemLv1[] = [
  {
    id: headerNavIdList.document,
    label: i18nHeaderNav.document,
    link: ROUTES.DOCUMENT,
  },
  supportHeaderNav,
]

export const languageSwitchOption = [
  {
    label: i18nCommon.ko,
    value: LANGUAGES.ko,
  },
  {
    label: i18nCommon.en,
    value: LANGUAGES.en,
  },
]
