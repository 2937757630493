export const service = {
  banner: {
    title: 'GameScale 서비스',
    description:
      'GameScale은 데이터와 공학적 사고에 기반한 분석 · 솔루션 · AI를 통해 게임 서비스의 고도화를 지원하며,\n개발 단계부터 라이브 서비스까지 모든 영역에서 품질을 올리기 위한 가장 쉬운 방법입니다.',
  },
  breadcrumbs: {
    home: '홈',
    service: '서비스',
    overview: '서비스 리스트',
  },
  detail: {
    guideDocs: '문서 보기',
    guideDocuments: '문서 보기',
    viewAllServices: '서비스 모두 보기',
    useCase: '활용 사례',
    descriptionUseCase: 'GameScale 서비스를 활용한 실제 사례를 만나보세요.',
  },
}
