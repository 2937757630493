import { useTranslation } from 'react-i18next'

import { i18nLanguage } from '@/i18n'
import TableOfContentItem from '@/pages/documents/components/table-of-content/table-of-content-items/table-of-content-item'
import { useDocumentsContext } from '@/pages/documents/context'
import { useSectionScroll } from '@/pages/documents/hook/useSectionScroll'

import styles from './table-of-content.module.scss'

export type TContent = {
  element: Element
  padding: number
  name: string
  id: string
  children: TContent[]
}

const TableOfContent = () => {
  const { iframeRef, tableOfContentData: nested } = useDocumentsContext()
  const activeId = useSectionScroll({ iframeRef, tableOfContentData: nested })
  const { t } = useTranslation()

  if (!nested.length) return null

  return (
    <div className={styles['table-of-content-wrapper']} id="table-of-content-wrapper">
      <div className={styles['table-of-content-main']} id="table-of-content-main">
        <p className={styles['header']}>{t(i18nLanguage.documents['table-of-contents'])}</p>
        <div className={styles['table-of-content-list']}>
          {nested.map((item) => {
            return (
              <TableOfContentItem
                key={item.id}
                name={item.name}
                id={item.id}
                data={item.children}
                activeId={activeId}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TableOfContent
