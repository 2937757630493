import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/button'
import { useLocale } from '@/hooks/use-locale'
import { HeaderMenuItemLv3 } from '@/layout/types'
import { useHeaderContext } from '@/providers/header-provider'

import classes from './menu-mobile-item-lv3.module.scss'

type HeaderMenuMobileItemLv3Props = {
  service: HeaderMenuItemLv3
}

export const HeaderMenuMobileItemLv3 = ({ service }: HeaderMenuMobileItemLv3Props) => {
  const locale = useLocale()
  const navigate = useNavigate()
  const { actions } = useHeaderContext()
  const { label, link } = service

  const handleClickHeaderItemLv3 = () => {
    if (link) {
      actions.closeMenu()
      navigate(`/${locale}/${link}`)
    }
  }
  return (
    <Button
      data-testid="header-mobile-menu-lv3-item"
      className={classes['header-mobile-menu-item-lv3']}
      onClick={handleClickHeaderItemLv3}
    >
      <span className={classes['item-label']}>{label}</span>
    </Button>
  )
}
