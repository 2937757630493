import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { Button } from '@/components/button'
import { ArrowBackIcon, ArrowMenuIcon, OverviewIcon } from '@/components/icons'
import { i18nLanguage } from '@/i18n'
import { HeaderMenuMobileItemLv2 } from '@/layout/header/menu-mobile/menu-mobile-item-lv2'
import { HeaderMenuItemLv1 } from '@/layout/types'
import { useHeaderContext } from '@/providers/header-provider'

import classes from './menu-mobile-item-lv1.module.scss'

type HeaderMenuMobileItemLv1Props = {
  headerItem: HeaderMenuItemLv1
  isLoading?: boolean
}

export const HeaderMenuMobileItemLv1 = ({
  headerItem,
  isLoading = false,
}: HeaderMenuMobileItemLv1Props) => {
  const navigate = useNavigate()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const {
    state: { activeMenu },
    actions,
  } = useHeaderContext()
  const { label, link, hasIcon, listLabel, serviceCategories, id } = headerItem

  const hasServiceCategories = serviceCategories && serviceCategories.length > 0
  const showMenuListChildren = activeMenu !== null && activeMenu === id

  const listChildrenLabel = listLabel ?? label

  const redirectToLink = () => {
    if (link) {
      actions.closeMenu()
      navigate(`/${language}/${link}`)
    }
  }

  const handleClickHeaderItemLv1 = () => {
    if (hasServiceCategories) return actions.setMenuId({ menuId: id })

    redirectToLink()
  }

  return (
    <div
      data-testid="header-mobile-menu-lv1-wrapper"
      className={classes['header-mobile-menu-item-lv1-wrapper']}
    >
      <Button
        data-testid="header-mobile-menu-lv1-item"
        disabled={isLoading}
        className={classes['header-mobile-menu-item-lv1']}
        onClick={handleClickHeaderItemLv1}
      >
        <span className={classes['item-label']}>{t(label)}</span>
        <div
          data-testid="header-mobile-menu-lv1-item-more-icon"
          className={clsx(classes['more-btn'], { [classes['show']]: hasServiceCategories })}
        >
          <ArrowMenuIcon />
        </div>
      </Button>

      <div
        className={clsx(classes['list-children'], {
          [classes['show']]: showMenuListChildren,
        })}
      >
        <Button
          data-testid="header-mobile-menu-back-btn"
          className={classes['back-btn']}
          onClick={actions.resetMenuId}
        >
          <div className={classes['arrow-back-icon']}>
            <ArrowBackIcon />
          </div>
          {t(i18nLanguage.common.button.back)}
        </Button>

        <Button
          data-testid="header-mobile-menu-lv1-list-label"
          className={clsx(classes['list-children-label'], {
            [classes['redirect-link']]: !!link,
          })}
          onClick={redirectToLink}
        >
          <div className={clsx(classes['overview-icon'], { [classes['show']]: hasIcon })}>
            <OverviewIcon />
          </div>
          {t(listChildrenLabel)}
        </Button>

        {hasIcon && (
          <div data-testid="header-mobile-separate" className={classes['list-children-separate']} />
        )}
        {hasServiceCategories &&
          serviceCategories.map((serviceCategory) => (
            <HeaderMenuMobileItemLv2 key={serviceCategory.id} serviceCategory={serviceCategory} />
          ))}
      </div>
    </div>
  )
}
