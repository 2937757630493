export const main = {
  heroSection: {
    title: 'Make a Difference \nwith GameScale Developers',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    viewDoc: '문서 보기',
  },
  contents: {
    learnMore: 'Learn More',
    viewAll: 'View All',
    service: {
      header: 'GameScale 서비스',
      gameScaleQuote: '개발부터 라이브 서비스까지, 품질을 확장하는 가장 쉬운 방법',
    },
    documentation: {
      header: '문서',
      doc1Title: '시작하기',
      doc1Desc: '추천하는 필수 문서를 확인하고 빠르게 개발을 시작해 보세요.',
      doc2Title: '서비스 연동',
      doc2Desc: '원하는 서비스 기능을 구현하기 위한 서비스 연동 상세문서를 참조하세요.',
      doc3Title: 'API Reference',
      doc3Desc: 'GameScale에서 API를 사용하는데 필요한 모든 정보를 안내해 드립니다.',
    },
    update: {
      header: '업데이트',
      changeLog: '릴리즈 노트',
      announcements: '공지사항',
    },
    news: {
      header: '뉴스',
    },
  },
}
