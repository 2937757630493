import { useCallback, useEffect, useMemo, useState } from 'react'

import { TContent } from '@/pages/documents/components/table-of-content'
import { getDocumentHeight } from '@/utils/get-document-height'

interface IUseSectionScroll {
  iframeRef: React.RefObject<HTMLIFrameElement>
  tableOfContentData: TContent[]
}

const nestedToArray = (tableOfContentData: TContent[]) => {
  return tableOfContentData.reduce((acc, item) => {
    acc.push(item)
    if (item.children.length) {
      acc.push(...nestedToArray(item.children))
    }
    return acc
  }, [] as TContent[])
}

export const useSectionScroll = ({ iframeRef, tableOfContentData }: IUseSectionScroll) => {
  const [currentId, setCurrentId] = useState<string>('')
  const elements = useMemo(() => {
    return nestedToArray(tableOfContentData).map((item) => {
      return iframeRef.current?.contentWindow?.document.getElementById(item.id)
    })
  }, [tableOfContentData, iframeRef])

  const handleScroll = useCallback(() => {
    if (!iframeRef.current) return

    const currentItem = elements.find((element) => {
      if (!element) return
      const rect = element.getBoundingClientRect()
      const mainContent = document.getElementById('main-content')

      return Math.round(rect.top) >= window.scrollY - (mainContent?.offsetTop ?? 0)
    })

    const isBottomDocument = window.scrollY >= getDocumentHeight() - window.innerHeight

    if (isBottomDocument) {
      const lastElement = elements[elements.length - 1]
      lastElement && setCurrentId(lastElement.id)
      return
    }

    if (currentItem) setCurrentId(currentItem.id)
  }, [iframeRef, elements])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return currentId
}
