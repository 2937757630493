import { SVGProps } from 'react'

export function ArrowDown({ className, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M14.6758 5.38916L7.96286 11.8718L1.48026 5.15883"
        stroke="currentColor"
        strokeWidth="1.4"
      />
    </svg>
  )
}
