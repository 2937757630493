import { useEffect } from 'react'
import { RemoveScroll } from 'react-remove-scroll'

import clsx from 'clsx'

import { useLocale } from '@/hooks/use-locale'
import { headerNavStatic, serviceHeaderNav } from '@/layout/configs'
import { LanguageMenu } from '@/layout/header/menu-mobile/language-menu'
import { HeaderMenuMobileItemLv1 } from '@/layout/header/menu-mobile/menu-mobile-item-lv1'
import { HeaderMenuItemLv2 } from '@/layout/types'
import { transformHeaderData } from '@/layout/utils'
import { useHeaderContext } from '@/providers/header-provider'
import { useGetServiceCategories } from '@/services/service'

import classes from './menu-mobile.module.scss'

export const HeaderMenuMobile = () => {
  const locale = useLocale()
  const {
    state: { isMenuOpen },
    actions,
  } = useHeaderContext()

  const { data: serviceCategories, isFetching } = useGetServiceCategories<HeaderMenuItemLv2[]>(
    {
      langType: locale,
      isIncludeService: true,
    },
    {
      select: transformHeaderData,
    },
  )

  useEffect(() => {
    const bodyClassList = document.body.classList

    bodyClassList.toggle(classes['body-hide-scrollbar'], isMenuOpen)

    return () => {
      bodyClassList.remove(classes['body-hide-scrollbar'])
    }
  }, [isMenuOpen])

  return (
    <div
      data-testid="header-mobile-menu-wrapper"
      className={clsx(classes['header-mobile-wrapper'], {
        [classes['open']]: isMenuOpen,
      })}
    >
      <RemoveScroll enabled={isMenuOpen}>
        <div
          data-testid="header-mobile-overlay"
          className={classes['header-mobile-overlay']}
          onClick={actions.closeMenu}
          aria-hidden="true"
        />
        <div data-testid="header-mobile-menu" className={classes['header-mobile-menu']}>
          <HeaderMenuMobileItemLv1
            isLoading={isFetching}
            headerItem={{ ...serviceHeaderNav, serviceCategories }}
          />
          {headerNavStatic.map((headerItem) => (
            <HeaderMenuMobileItemLv1 key={headerItem.label} headerItem={headerItem} />
          ))}
          <div
            data-testid="header-mobile-language-menu"
            className={classes['language-menu-wrapper']}
          >
            <LanguageMenu />
          </div>
        </div>
      </RemoveScroll>
    </div>
  )
}
