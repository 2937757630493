import {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import { TContent } from '@/pages/documents/components/table-of-content'
import { TMarkdownContentErrorState } from '@/pages/documents/types'
import { ResultFormatBreadcrumbs } from '@/pages/documents/utils'

export type TDocumentsContextValue = {
  path: string
  setPath: Dispatch<SetStateAction<string>>
  markdownContentError: TMarkdownContentErrorState
  setMarkdownContentError: Dispatch<SetStateAction<TMarkdownContentErrorState>>
  breadcrumbs: ResultFormatBreadcrumbs[]
  setBreadcrumbs: Dispatch<SetStateAction<ResultFormatBreadcrumbs[]>>
  tableOfContentData: TContent[]
  setTableOfContentData: Dispatch<SetStateAction<TContent[]>>
  iframeRef: RefObject<HTMLIFrameElement>
  isMarkdownChanged: boolean
  setIsMarkdownChanged: Dispatch<SetStateAction<boolean>>
  openSidebarMobile: boolean
  setOpenSidebarMobile: Dispatch<SetStateAction<boolean>>
  errorCode: number | null
  setErrorCode: Dispatch<SetStateAction<number | null>>
  isMarkdownInitialized: boolean
  setIsMarkdownInitialized: Dispatch<SetStateAction<boolean>>
}

export const DocumentsContext = createContext<TDocumentsContextValue>({} as TDocumentsContextValue)

export const DocumentsProvider = ({ children }: { children: ReactNode }) => {
  const [path, setPath] = useState<string>('')
  const [breadcrumbs, setBreadcrumbs] = useState<ResultFormatBreadcrumbs[]>([])
  const [markdownContentError, setMarkdownContentError] = useState<TMarkdownContentErrorState>({
    error: false,
    type: null,
  })
  const [tableOfContentData, setTableOfContentData] = useState<TContent[]>([])
  const [isMarkdownChanged, setIsMarkdownChanged] = useState<boolean>(false)
  const [openSidebarMobile, setOpenSidebarMobile] = useState<boolean>(false)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [isMarkdownInitialized, setIsMarkdownInitialized] = useState<boolean>(false)

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const value = useMemo(() => {
    return {
      path,
      setPath,
      breadcrumbs,
      setBreadcrumbs,
      iframeRef,
      markdownContentError,
      setMarkdownContentError,
      tableOfContentData,
      setTableOfContentData,
      isMarkdownChanged,
      setIsMarkdownChanged,
      openSidebarMobile,
      setOpenSidebarMobile,
      errorCode,
      setErrorCode,
      isMarkdownInitialized,
      setIsMarkdownInitialized,
    }
  }, [
    path,
    breadcrumbs,
    markdownContentError,
    tableOfContentData,
    isMarkdownChanged,
    openSidebarMobile,
    errorCode,
    isMarkdownInitialized,
  ])
  return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>
}

export const useDocumentsContext = () => {
  const context = useContext<TDocumentsContextValue>(DocumentsContext)
  if (!context) {
    throw new Error('useDocumentsContext must be used within a DocumentsProvider')
  }

  return context
}
