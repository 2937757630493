import { useTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { Button } from '@/components/button'
import {
  headerNavIdList,
  headerNavStatic,
  serviceHeaderNav,
  supportHeaderNav,
} from '@/layout/configs'
import { HeaderDesktopMenuList } from '@/layout/header/menu-desktop/menu-list'
import { HeaderMenuItemLv2 } from '@/layout/types'
import { transformHeaderData } from '@/layout/utils'
import { useHeaderContext } from '@/providers/header-provider'
import { useGetServiceCategories } from '@/services/service'
import { Locale } from '@/types/locales'

import classes from './menu-desktop.module.scss'

export const HeaderDesktopMenu = () => {
  const navigate = useNavigate()
  const {
    state: { isMenuOpen, activeMenu },
    actions,
  } = useHeaderContext()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { data: serviceCategories, isFetching } = useGetServiceCategories<HeaderMenuItemLv2[]>(
    {
      langType: language as Locale,
      isIncludeService: true,
    },
    {
      select: transformHeaderData,
    },
  )

  const showHeaderDesktopMenu = Boolean(isMenuOpen && activeMenu)

  const handleMenuClick = (id: string, link?: string) => {
    if (id === headerNavIdList.document) {
      actions.resetMenu()
      navigate(`/${language}/${link}`)
      return
    }

    if (activeMenu === id) return actions.resetMenu()

    actions.setMenuId({
      menuId: id,
    })
  }

  return (
    <div className={classes['header-desktop-wrapper']}>
      <Button
        data-testid="header-desktop-menu-item"
        className={classes['menu-item']}
        onClick={() => handleMenuClick(serviceHeaderNav.id)}
      >
        {t(serviceHeaderNav.label)}
      </Button>

      {headerNavStatic.map((nav) => (
        <Button
          data-testid="header-desktop-menu-item"
          key={t(nav.label)}
          className={classes['menu-item']}
          onClick={() => handleMenuClick(nav.id, nav.link)}
        >
          {t(nav.label)}
        </Button>
      ))}

      <RemoveScroll enabled={showHeaderDesktopMenu}>
        <div
          data-testid="header-desktop-fixed"
          className={clsx(classes['header-desktop-fixed'], {
            [classes['open']]: showHeaderDesktopMenu,
          })}
        >
          <div
            data-testid="header-desktop-overlay"
            className={clsx(classes['header-desktop-overlay'], {
              [RemoveScroll.classNames.zeroRight]: showHeaderDesktopMenu,
            })}
            onClick={actions.resetMenu}
            aria-hidden="true"
          />
          <div
            data-testid="header-desktop-menu-list-wrapper"
            className={classes['header-desktop-menu']}
          >
            <HeaderDesktopMenuList
              key="header-desktop-service-menu"
              isOpen={activeMenu === headerNavIdList.service}
              menuData={{ ...serviceHeaderNav, serviceCategories }}
              isLoading={isFetching}
            />
            <HeaderDesktopMenuList
              key="header-desktop-support-menu"
              isOpen={activeMenu === headerNavIdList.support}
              menuData={supportHeaderNav}
            />
          </div>
        </div>
      </RemoveScroll>
    </div>
  )
}
