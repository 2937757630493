import { useRef, useState } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import { useLocation } from 'react-router-dom'

import clsx from 'clsx'

import { Button } from '@/components/button'
import { CloseMenuIcon, LogoIcon, MenuIcon } from '@/components/icons'
import { RouterLink } from '@/components/router-link'
import { ROUTES } from '@/configs/paths'
import useEventListener from '@/hooks/use-event-listener'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import useOnClickOutside from '@/hooks/use-on-click-outside'
import { HeaderControl } from '@/layout/header/header-control'
import { HeaderDesktopMenu } from '@/layout/header/menu-desktop'
import { HeaderMenuMobile } from '@/layout/header/menu-mobile'
import { isHideHeaderSpace } from '@/layout/utils'
import { useHeaderContext } from '@/providers/header-provider'

import classes from './header.module.scss'

function Header({ isErrorPage }: Readonly<{ isErrorPage?: boolean }>) {
  const { pathname } = useLocation()
  const [isActiveBg, setIsActiveBg] = useState(false)
  const headerMenuRef = useRef(null)

  const isDesktop = useMediaQuery(mediaQueryBreakpoints.up('lg'))
  const { state, actions } = useHeaderContext()
  const { isSearchBarOpen, isMenuOpen } = state

  const hideHeaderSpace = isErrorPage ? false : isHideHeaderSpace(pathname)

  const handleScroll = () => {
    const isScrolled = window.scrollY > 0
    setIsActiveBg(isScrolled)
  }

  useEventListener({
    eventType: 'scroll',
    handler: handleScroll,
  })

  useOnClickOutside(headerMenuRef, (event) => {
    const floatingHeaderDesktopMenu = document.getElementById('floating-header-menu-list')
    const isClickOnFloatingMenu = floatingHeaderDesktopMenu
      ? floatingHeaderDesktopMenu.contains(event.target as Node)
      : false

    if (isClickOnFloatingMenu) return

    if (isMenuOpen) actions.resetMenu()
  })

  return (
    <>
      <div
        data-testid="header-height"
        className={clsx(classes['header-height'], { [classes['hide']]: hideHeaderSpace })}
      />
      <header
        className={clsx(classes['header'], {
          [classes['search-bar-open']]: isSearchBarOpen,
          [classes['header-active-bg']]: isActiveBg || isSearchBarOpen || isMenuOpen,
          [classes['header-transparent']]: hideHeaderSpace,
          [RemoveScroll.classNames.fullWidth]: isMenuOpen,
        })}
      >
        <Button
          data-testid="header-mobile-menu-btn"
          className={classes['menu-btn']}
          onClick={actions.toggleMenu}
        >
          {isMenuOpen ? (
            <CloseMenuIcon data-testid="header-close-icon" />
          ) : (
            <MenuIcon data-testid="header-menu-icon" />
          )}
        </Button>
        <RouterLink data-testid="header-logo" href={ROUTES.MAIN} className={classes['logo']}>
          <LogoIcon />
        </RouterLink>

        <div className={classes['header-menu-wrapper']} ref={headerMenuRef}>
          {isDesktop ? (
            <div data-testid="header-desktop-menu" className={classes['desktop-menu-wrapper']}>
              <HeaderDesktopMenu />
            </div>
          ) : (
            <HeaderMenuMobile />
          )}
        </div>

        <div className={classes['header-control-wrapper']}>
          <HeaderControl />
        </div>
      </header>
    </>
  )
}

export default Header
