import { useTranslation } from 'react-i18next'
import { Fragment } from 'react/jsx-runtime'

import clsx from 'clsx'

import { Button } from '@/components/button'
import { LanguageIcon } from '@/components/icons'
import { languageSwitchOption } from '@/layout/configs'

import classes from './language-menu.module.scss'

export const LanguageMenu = () => {
  const {
    i18n: { language: currentLanguage, changeLanguage },
    t,
  } = useTranslation()

  const handleChangeLanguage = (lang: string) => {
    if (lang === currentLanguage) return

    changeLanguage(lang)
  }

  return (
    <div className={classes['language-menu']}>
      <div data-testid="header-language-icon" className={classes['language-icon']}>
        <LanguageIcon />
      </div>
      <div data-testid="header-language-list" className={classes['language-list']}>
        {languageSwitchOption.map((option, index) => {
          const addSeparator = index !== languageSwitchOption.length - 1

          return (
            <Fragment key={option.value}>
              <Button
                data-testid="header-language-list-item"
                className={clsx(classes['language-list-item'], {
                  [classes['active']]: currentLanguage === option.value,
                })}
                key={t(option.label)}
                onClick={() => handleChangeLanguage(option.value)}
              >
                {t(option.label)}
              </Button>
              {addSeparator && (
                <div
                  data-testid="header-language-separator"
                  className={classes['language-separator']}
                />
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}
