import { NestedDocumentDTO } from '@/services/documents/documents.dto'
import { Locale } from '@/types/locales'
import { Response } from '@/types/response'

export enum EPermissionView {
  public,
  private,
}

export type TGetNestedTreeParams = {
  langType: Locale
  permissionView: EPermissionView
}

export type TNestedTreeResponse = Response<NestedDocumentDTO[]>
