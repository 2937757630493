import { LanguageType } from '@/types/common'

export const ROUTES = {
  MAIN: '',
  NOTICE_LIST: 'support/notice-list',
  NOTICE_DETAIL: 'support/notice-list/:id',
  NEWS_LIST: 'support/news-list',
  NEWS_DETAIL: 'support/news-list/:id',
  SERVICE_OVERVIEW: 'services',
  SERVICE_DETAIL: 'services/:id',
  DOCUMENT: 'document',
  SEARCH: 'search',
} as const

export const ROUTES_URL = {
  NOTICE_LIST: (language: string) => `/${language}/${ROUTES.NOTICE_LIST}` as const,
  NOTICE_DETAIL: (id: number, language: string) => {
    return `/${language}/support/notice-list/${id}` as const
  },
  SERVICE_OVERVIEW: (language: string) => `/${language}/${ROUTES.SERVICE_OVERVIEW}` as const,
  NEWS_DETAIL: (id: number, language: LanguageType) =>
    `/${language}/${ROUTES.NEWS_LIST}/${id}` as const,
  SERVICE_DETAIL: (id: number, language: LanguageType) =>
    `/${language}/${ROUTES.SERVICE_OVERVIEW}/${id}` as const,
  DOCUMENT_DETAIL: (path: string, language: LanguageType) =>
    `/${language}/${ROUTES.DOCUMENT}?path=${path}` as const,
}
