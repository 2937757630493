import apiService from '@/configs/axios'
import { newsEndpoints } from '@/services/news/news.config'
import {
  TGetNewsListParams,
  TNewsDetailResponse,
  TNewsListResponse,
  TNewsParams,
} from '@/services/news/news.type'
import { Locale } from '@/types/locales'

const productType = 'developers'

export const newsAPI = {
  getMainNewsList: (params: TNewsParams): Promise<TNewsListResponse> =>
    apiService.get(newsEndpoints.getLatest(), {
      params: {
        langType: params.langType,
        isIncludeService: params.isIncludeService ? 1 : 0,
        limit: params.limit,
        productType: params.productType,
      },
    }),
  getNewsHeroList: (langType: Locale): Promise<TNewsListResponse> =>
    apiService.get(newsEndpoints.getFixed(), { params: { langType, productType } }),
  getNewsByNewsId: (id: string, langType: Locale): Promise<TNewsDetailResponse> =>
    apiService.get(newsEndpoints.getById(id), { params: { langType, productType } }),
  getNewsList: (params: TGetNewsListParams): Promise<TNewsListResponse> =>
    apiService.get(newsEndpoints.getList(), {
      params: {
        ...params,
        productType,
      },
    }),
}
