import axios, { HttpStatusCode } from 'axios'
import queryString from 'query-string'

import { apiVersion } from '@/configs/common'
import { authAPIs } from '@/services/auth/auth.api'
import { authEndpoints } from '@/services/auth/auth.config'

let refreshTokenRequest: ReturnType<(typeof authAPIs)['refreshToken']> | null = null

const apiService = axios.create({
  baseURL: apiVersion.v1,
  headers: {},
  paramsSerializer: (params) =>
    queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    }),
})

apiService.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

apiService.interceptors.response.use(
  function (response) {
    return response.data
  },
  async function (error) {
    if (error.response.data.error.errorCode.includes('UNAUTHORIZED_ACCESS_POST'))
      return Promise.reject(error)
    let refreshTokenSuccess = false
    const originalRequest = error.config
    const isRefreshTokenError = error.config.url === authEndpoints.refreshToken
    const shouldRenewToken =
      error.response?.status === HttpStatusCode.Unauthorized && !originalRequest._retry

    if (isRefreshTokenError) return Promise.reject(error)

    if (shouldRenewToken) {
      originalRequest._retry = true

      try {
        refreshTokenRequest = refreshTokenRequest ?? authAPIs.refreshToken()
        const response = await refreshTokenRequest
        refreshTokenSuccess = response.success
      } catch (refreshError) {
        refreshTokenSuccess = false
      } finally {
        refreshTokenRequest = null
      }

      if (refreshTokenSuccess) return apiService(originalRequest)
      authAPIs.redirectLogin()
      return Promise.reject(error)
    }

    return Promise.reject(error)
  },
)

export default apiService
