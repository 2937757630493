export const error = {
  notFound: {
    info: '죄송합니다. 페이지를 찾을 수 없습니다.',
    description: `존재하지 않는 주소이거나 요청하신 페이지의 주소가 <br /> 변경 또는 삭제되어 찾을 수가 없습니다. <br /> 문의 사항은 <br /> <strong>docs_mgmt@nexon.co.kr</strong>로 연락 부탁드립니다.`,
  },

  forbidden: {
    description: `해당 메뉴 및 페이지에 대한 <br /> 접근 권한이 없습니다.`,
  },

  unauthorized: {
    description: '해당 페이지는 로그인 이후 이용 가능합니다.',
    button: '로그인',
  },
}
