import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { Button } from '@/components/button'
import { ChevronDownIcon, LanguageIcon } from '@/components/icons'
import useOnClickOutside from '@/hooks/use-on-click-outside'
import { languageSwitchOption } from '@/layout/configs'
import { useLanguageSwitchContext } from '@/providers/language-switch-provider'

import styles from './language-switcher.module.scss'

export const LanguageSwitcher = () => {
  const {
    i18n: { language: currentLanguage, changeLanguage },
    t,
  } = useTranslation()
  const { isShowMenu, setIsShowMenu } = useLanguageSwitchContext()
  const languageSwitcherRef = useRef<HTMLDivElement>(null)

  const handleChangeLanguage = (lang: string) => {
    if (lang === currentLanguage) return

    setIsShowMenu(false)
    changeLanguage(lang)
  }

  useOnClickOutside(languageSwitcherRef, () => setIsShowMenu(false))

  return (
    <div
      data-testid="footer-language-switcher"
      ref={languageSwitcherRef}
      className={styles['language-switcher']}
    >
      <Button
        data-testid="footer-language-switcher-btn"
        className={clsx(styles['language-switch-btn'], {
          [styles['show']]: isShowMenu,
        })}
        onClick={() => setIsShowMenu((prev) => !prev)}
      >
        <span data-testid="footer-language-icon" className={styles['language-icon']}>
          <LanguageIcon />
        </span>
        <span
          data-testid="footer-switch-language-icon"
          className={clsx(styles['chevron-icon'], { [styles['rotate']]: isShowMenu })}
        >
          <ChevronDownIcon />
        </span>
      </Button>

      <div
        data-testid="footer-language-menu"
        className={clsx(styles['language-menu'], {
          [styles['show-menu']]: isShowMenu,
        })}
      >
        {languageSwitchOption.map((option) => (
          <Button
            data-testid="footer-language-menu-item"
            className={clsx(styles['language-menu-item'], {
              [styles['active']]: currentLanguage === option.value,
            })}
            key={t(option.label)}
            onClick={() => handleChangeLanguage(option.value)}
          >
            {t(option.label)}
          </Button>
        ))}
      </div>
    </div>
  )
}
