export function OverviewIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Ico_serviceOverview">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.32471 5.05621C4.32471 4.65733 4.64806 4.33398 5.04693 4.33398H11.4023C11.8012 4.33398 12.1245 4.65733 12.1245 5.05621V11.4118C12.1245 11.8106 11.8012 12.134 11.4023 12.134H5.04693C4.64806 12.134 4.32471 11.8106 4.32471 11.4118V5.05621ZM4.32471 14.8062C4.32471 14.4073 4.64806 14.084 5.04693 14.084H11.4023C11.8012 14.084 12.1245 14.4073 12.1245 14.8062V21.1618C12.1245 21.5606 11.8012 21.884 11.4023 21.884H5.04693C4.64806 21.884 4.32471 21.5606 4.32471 21.1618V14.8062ZM14.7969 14.084C14.3981 14.084 14.0747 14.4073 14.0747 14.8062V21.1618C14.0747 21.5606 14.3981 21.884 14.7969 21.884H21.1523C21.5512 21.884 21.8745 21.5606 21.8745 21.1618V14.8062C21.8745 14.4073 21.5512 14.084 21.1523 14.084H14.7969Z"
          fill="black"
        />
        <rect
          id="Rectangle 34625670"
          x="14.0918"
          y="4.33203"
          width="7.79979"
          height="7.8"
          rx="0.802469"
          fill="#4A72FF"
        />
      </g>
    </svg>
  )
}
