import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { getUrlWithLanguage } from '@/utils/language-url'

const useRedirectWhenChangeLanguage = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { pathname, hash, state, search } = useLocation()
  const currentLanguage = useRef(i18n.language)

  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
  }, [])

  useEffect(() => {
    if (i18n.language === currentLanguage.current) return
    if (isMounted.current === false) return

    currentLanguage.current = i18n.language
    navigate(
      {
        pathname: getUrlWithLanguage(pathname, i18n.language),
        hash,
        search,
      },
      {
        state,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])
}

export default useRedirectWhenChangeLanguage
