import { AnnouncementDetailParams, AnnouncementParams } from './announcement.dto'

export const announcementEndpoints = {
  getList: () => '/announcement' as const,
  getDetail: (id: number) => `/announcement/${id}` as const,
}

export const announcementKeys = {
  all: ['announcement'] as const,
  list: () => [...announcementKeys.all, 'list'] as const,
  listWithParams: (params: AnnouncementParams) => [...announcementKeys.list(), params] as const,
  detail: () => [...announcementKeys.all, 'detail'] as const,
  detailWithParams: (params: AnnouncementDetailParams) =>
    [...announcementKeys.detail(), params] as const,
}
