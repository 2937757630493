import { CSSProperties, FC, MouseEvent, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import { ChevronDownDocumentsIcon, ChevronRightDocumentsIcon } from '@/components/icons'
import { eventEmitter } from '@/pages/documents/components/document-directory/document-directory'
import { GETTING_STARTED_DOCUMENT_PATH } from '@/pages/documents/constants'
import { useDocumentsContext } from '@/pages/documents/context'
import { ResultFormatBreadcrumbs } from '@/pages/documents/utils'
import { NestedDocumentDTO } from '@/services/documents'
import { backToTop } from '@/utils/back-to-top'

import styles from './render-children.module.scss'

type TRenderChildrenProps = {
  data: NestedDocumentDTO[] | undefined
  recursionDepth: number
  breadcrumbs: ResultFormatBreadcrumbs[]
  isPrivate: boolean
}

type TRecursiveChildrenProps = {
  data: NestedDocumentDTO | undefined
  recursionDepth: number
  breadcrumbs: ResultFormatBreadcrumbs[]
  isPrivate: boolean
}
const RecursiveChildren: FC<TRecursiveChildrenProps> = ({
  data,
  recursionDepth,
  breadcrumbs,
  isPrivate,
}) => {
  const [open, setOpen] = useState(false)
  const { setOpenSidebarMobile } = useDocumentsContext()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { state } = useLocation()

  const path = searchParams.get('path')

  useLayoutEffect(() => {
    if (path === GETTING_STARTED_DOCUMENT_PATH && !state?.isSameLocation) setOpen(false)
  }, [path])

  useEffect(() => {
    let urlMatchDoc = breadcrumbs[recursionDepth]?.key
    if (isPrivate) {
      urlMatchDoc = breadcrumbs[recursionDepth - 1]?.key
    }
    if (urlMatchDoc === data?.key) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs, isPrivate])

  const chunk = path?.split('/') ?? []
  const last = chunk[chunk.length - 1]

  useEffect(() => {
    if (data?.markdownPath === `${path}.md`) {
      const chunk = path?.split('/') ?? []
      const last = chunk[chunk.length - 1]
      eventEmitter.emit('broadcast', last)
    }
  }, [data?.markdownPath, data?.title, path])

  if (!data) return null
  const style: CSSProperties = {
    '--recursion-depth': recursionDepth,
  } as CSSProperties
  const hasChildren = data.children.length > 0
  const handleClickExpand = (e: MouseEvent) => {
    e.stopPropagation()
    if (hasChildren) setOpen((open) => !open)
  }
  const handleClickTitle = () => {
    if (hasChildren) setOpen(true)
    if (data.markdownPath !== null && data.markdownPath !== '') {
      const newParams = new URLSearchParams(searchParams.toString())
      newParams.set('path', data.markdownPath.replace('.md', ''))
      newParams.delete('anchor')
      const newUrl = `${window.location.pathname}?${decodeURIComponent(newParams.toString())}`
      navigate(newUrl, {
        state: {
          isSameLocation: true,
        },
      })
      backToTop()
      setOpenSidebarMobile(false)
    }
  }

  return (
    <>
      <div
        id={data.markdownPath === `${path}.md` ? last : undefined}
        key={data.key}
        className={styles['parent-container']}
        style={style}
        onClick={handleClickTitle}
        aria-hidden="true"
      >
        <div className={styles['button-container']}>
          {hasChildren && (
            <button
              onClick={handleClickExpand}
              style={{
                height: recursionDepth === 1 ? '25px' : '22px',
              }}
            >
              {open ? <ChevronDownDocumentsIcon /> : <ChevronRightDocumentsIcon />}
            </button>
          )}

          <p
            className={clsx(styles['title'], {
              [styles['title-no-chev']]: !data.children.length,
              [styles['title-active']]: data.markdownPath === `${path}.md`,
              [styles['title-main']]: recursionDepth === 1,
            })}
          >
            {data.title}
          </p>
        </div>
      </div>
      {data.children && hasChildren && (
        <div className={clsx({ [styles['collapse']]: !open })}>
          <RenderChildren
            data={data.children}
            recursionDepth={recursionDepth + 1}
            breadcrumbs={breadcrumbs}
            isPrivate={isPrivate}
          />
        </div>
      )}
      {recursionDepth === 1 && <div className={styles['divider']} />}
    </>
  )
}

export const RenderChildren: FC<TRenderChildrenProps> = ({
  data,
  recursionDepth,
  breadcrumbs,
  isPrivate,
}) => {
  if (!data) return null
  return (
    <>
      {data.map((item) => (
        <RecursiveChildren
          key={item.key}
          data={item}
          recursionDepth={recursionDepth}
          breadcrumbs={breadcrumbs}
          isPrivate={isPrivate}
        />
      ))}
    </>
  )
}
