import { useTranslation } from 'react-i18next'
import { Link, LinkProps } from 'react-router-dom'

type RouterLinkProps = Omit<LinkProps, 'to'> & {
  href: string
  isExternal?: boolean
}

export const RouterLink = ({ children, href, isExternal = false, ...props }: RouterLinkProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  const linkProps = {
    href: isExternal ? href : `/${language}${href}`,
    target: isExternal ? '_blank' : undefined,
  }

  return (
    <Link to={linkProps.href} target={linkProps.target} {...props}>
      {children}
    </Link>
  )
}
