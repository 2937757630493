import { PropsWithChildren, createContext, useContext, useMemo, useReducer } from 'react'

import { buildHeaderActions } from '@/providers/header-provider/actions'
import { headerDefaultState } from '@/providers/header-provider/configs'
import { headerReducer } from '@/providers/header-provider/reducer'
import { HeaderContextProps } from '@/providers/header-provider/types'

const HeaderContext = createContext<HeaderContextProps | null>(null)

export const HeaderProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(headerReducer, headerDefaultState)

  const actions = buildHeaderActions(dispatch)
  const value = useMemo(() => ({ state, dispatch, actions }), [actions, state])

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export const useHeaderContext = () => {
  const context = useContext(HeaderContext)

  if (!context) throw new Error('useHeaderContext must be used within a HeaderProvider')

  return context
}
