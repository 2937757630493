export const meta = {
  main: {
    title: 'GameScale Developers',
    ogTitle: 'GameScale Developers',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
  serviceOverview: {
    title: 'GameScale Developers | Service List',
    ogTitle: 'GameScale Developers | Service List',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
  serviceDetail: {
    title: 'GameScale Developers | {{detail}}',
    ogTitle: 'GameScale Developers | {{detail}}',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
  documentation: {
    title: 'GameScale Developers | {{detail}}',
    ogTitle: 'GameScale Developers | {{detail}}',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
  announcementList: {
    title: 'GameScale Developers | Announcements',
    ogTitle: 'GameScale Developers | Announcements',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
  announcementDetail: {
    title: 'GameScale Developers | {{detail}}',
    ogTitle: 'GameScale Developers | {{detail}}',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
  newsList: {
    title: 'GameScale Developers | News',
    ogTitle: 'GameScale Developers | News',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
  newsDetail: {
    title: 'GameScale Developers | {{detail}}',
    ogTitle: 'GameScale Developers | {{detail}}',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
  searchResult: {
    title: 'GameScale Developers | Search',
    ogTitle: 'GameScale Developers | Search',
    description:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
    ogDescription:
      'We provide comprehensive documentation introducing the GameScale service, including the necessary APIs and development processes for feature implementation.',
  },
}
