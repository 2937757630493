export const ChevronRightDocumentsIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Property 1=ico_arrowDOCS_Closed">
      <path
        id="Vector 28"
        d="M3.5 1L7.42958 5.0692L3.36038 8.99878"
        stroke="#CCCCCC"
        strokeWidth="1.3"
      />
    </g>
  </svg>
)
