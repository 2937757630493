import { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Fragment } from 'react/jsx-runtime'

import '@/assets/styles/main-layout.scss'
import ScrollToTopButton from '@/components/scroll-to-top-button'
import useBackToTopNewPage from '@/hooks/use-back-to-top-new-page'
import useRedirectWhenChangeLanguage from '@/hooks/use-redirect-when-language-change'
import { getLayoutThemes } from '@/layout/utils'

import Body from './body'
import Footer from './footer'
import Header from './header'

interface MainLayoutProps {
  children?: React.ReactNode
}
function MainLayout({ children }: MainLayoutProps) {
  useRedirectWhenChangeLanguage()
  useBackToTopNewPage()

  const { pathname } = useLocation()
  const footerTheme = getLayoutThemes(pathname)

  useEffect(() => {
    document.documentElement.setAttribute('theme', footerTheme)

    return () => {
      document.documentElement.removeAttribute('theme')
    }
  }, [footerTheme])

  return (
    <Fragment>
      <Header />
      <Body>
        <Suspense>{children ?? <Outlet />}</Suspense>
        <ScrollToTopButton />
      </Body>
      <Footer />
    </Fragment>
  )
}

export default MainLayout
