import { headerActionTypes } from '@/providers/header-provider/configs'
import { HeaderDispatch } from '@/providers/header-provider/types'

export const buildHeaderActions = (dispatch: HeaderDispatch) => {
  return {
    openSearchBar: () => {
      dispatch({ type: headerActionTypes.openSearchBar })
    },
    closeSearchBar: () => {
      dispatch({ type: headerActionTypes.closeSearchBar })
    },
    openMenu: () => {
      dispatch({ type: headerActionTypes.openHeaderMenu })
    },
    closeMenu: () => {
      dispatch({ type: headerActionTypes.closeHeaderMenu })
    },
    toggleMenu: () => {
      dispatch({ type: headerActionTypes.toggleHeaderMenu })
    },
    resetMenu: () => {
      dispatch({ type: headerActionTypes.resetHeaderMenu })
    },
    setMenuId: (payload: { menuId: string }) => {
      dispatch({ type: headerActionTypes.setHeaderMenuId, payload })
    },
    resetMenuId: () => {
      dispatch({ type: headerActionTypes.resetHeaderMenuId })
    },
    setSubmenuId: (payload: { submenuId: string }) => {
      dispatch({ type: headerActionTypes.setHeaderSubMenuId, payload })
    },
    resetSubmenuId: () => {
      dispatch({ type: headerActionTypes.resetHeaderSubMenuId })
    },
  }
}
