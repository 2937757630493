export const header = {
  meta: {
    title: 'GameScale | 넥슨의 게임 개발 솔루션 게임스케일',
    description:
      'GameScale은 게임 개발뿐만 아니라 게임 보안/마케팅/운영까지, 게임을 위한 전문적인 솔루션을',
  },
  nav: {
    service: '서비스',
    serviceOverview: '서비스 리스트',
    document: '문서',
    support: '지원',
    announcements: '공지사항',
    news: '뉴스',
  },
  searchPlaceholder: '무엇이 궁금하신가요?',
  login: '로그인',
  logout: '로그아웃',
}
