export const service = {
  banner: {
    title: 'GameScale Services',
    description:
      "GameScale enhances your game services through data-driven analysis, innovative solutions, and AI-powered insights.\nIt's the easiest way to elevate quality across all stages, from development to live service.",
  },
  breadcrumbs: {
    home: 'Home',
    service: 'Service',
    overview: 'Service List',
  },
  detail: {
    guideDocs: 'Guide Docs',
    guideDocuments: 'Guide Documentations',
    viewAllServices: 'View All Services',
    useCase: 'Use Cases',
    descriptionUseCase: 'Read about inspiring success stories achieved with GameScale.',
  },
}
