import { ServiceCategoryParams, ServiceDetailParams } from '@/services/service'

export const serviceCategoryEndpoints = {
  getList: () => '/service-category' as const,
}

export const serviceEndpoint = {
  getList: () => '/services' as const,
  getDetail: (id: number) => `${serviceEndpoint.getList()}/${id}` as const,
}

export const serviceCategoryKeys = {
  withParams: (params: ServiceCategoryParams) =>
    [serviceCategoryEndpoints.getList(), params] as const,
  withDetailParams: (params: ServiceDetailParams) =>
    [serviceEndpoint.getDetail(params.id), params] as const,
}
