export const common = {
  news: 'News',
  docs: 'Docs',
  en: 'EN',
  ko: 'KO',
  footer: '© Nexon Korea Corporation All Rights Reserved.',
  privacyPolicy: 'Privacy Policy',
  home: 'Home',
  support: 'Support',
  announcements: 'Announcements',
  button: {
    cancel: 'Cancel',
    back: 'Back',
  },
  navigation: {
    previous: 'Prev',
    next: 'Next',
    backToList: 'Back to List',
  },
}
