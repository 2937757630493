import { useRef, useState } from 'react'

import clsx from 'clsx'
import dayjs from 'dayjs'

import { LinkIcon } from '@/components/icons'
import { DoneIcon } from '@/components/icons/done'
import ProgressBar from '@/components/progress-bar'
import { NEWS_DATE_FORMAT } from '@/configs/common'
import useEventListener from '@/hooks/use-event-listener'
import useBuildT from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n'
import { NewsDTO } from '@/services/news'

import classes from './news-detail-hero-section.module.scss'

interface NewsDetailHeroSectionProps {
  data?: NewsDTO
}

const TOAST_MESSAGE_TIMEOUT = 2000

function NewsDetailHeroSection({ data }: Readonly<NewsDetailHeroSectionProps>) {
  const [isShow, setIsShow] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [enableToastMessage, setEnableToastMessage] = useState(false)

  const t = useBuildT()

  const handleScroll = () => {
    if (!ref.current) return
    const height = ref.current.offsetHeight
    setIsShow(window.scrollY > height)
  }

  useEventListener({ eventType: 'scroll', handler: handleScroll })

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
    setEnableToastMessage(true)
    setTimeout(() => setEnableToastMessage(false), TOAST_MESSAGE_TIMEOUT)
  }

  return (
    <>
      <div className={classes.heroWrapper} ref={ref}>
        <div className={classes.container}>
          <div className={classes.top}>
            <div className={classes.category}>{data?.category.name}</div>
            <div className={classes.title}>{data?.title}</div>
          </div>
          <div className={classes.bottom}>
            <div className={classes.date}>
              {data && dayjs(data.createdDate).format(NEWS_DATE_FORMAT)}
            </div>
            <div className={classes['copy-container']}>
              <div
                className={clsx(classes['copy-wrapper'], {
                  [classes.animation]: enableToastMessage,
                })}
              >
                <DoneIcon />
                <p className={classes['copy-text']}>
                  {t(i18nLanguage.support.newsDetail.toastMessage)}
                </p>
              </div>
              <button className={classes.icon} onClick={handleCopyToClipboard}>
                <LinkIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(classes.progressBar, isShow && classes.show)}>
        <ProgressBar />
      </div>
    </>
  )
}

export default NewsDetailHeroSection
