import { HeaderState } from '@/providers/header-provider/types'

export const headerDefaultState: HeaderState = {
  activeMenu: null,
  activeSubmenu: null,
  isMenuOpen: false,
  isSearchBarOpen: false,
}

export const headerActionTypes = {
  openSearchBar: 'open_search_bar',
  closeSearchBar: 'close_search_bar',
  openHeaderMenu: 'open_header_menu',
  closeHeaderMenu: 'close_header_menu',
  toggleHeaderMenu: 'toggle_header_menu',
  resetHeaderMenu: 'reset_header_menu',
  setHeaderMenuId: 'set_header_menuId',
  resetHeaderMenuId: 'reset_header_menuId',
  setHeaderSubMenuId: 'set_header_submenuId',
  resetHeaderSubMenuId: 'set_header_subMenuId',
} as const
