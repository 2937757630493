export function ArrowSubMenuIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M4.19531 1.20117L8.99531 6.00117L4.19531 10.8012"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
