import { SVGProps } from 'react'

export const AlertIcon = ({
  width = 63,
  height = 62,
  className,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 63 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.487 30.9999C59.487 46.694 46.7644 59.4166 31.0703 59.4166C15.3762 59.4166 2.65365 46.694 2.65365 30.9999C2.65365 15.3058 15.3762 2.58325 31.0703 2.58325C46.7644 2.58325 59.487 15.3058 59.487 30.9999ZM33.6795 43.9166H28.487V38.7499H33.6795V43.9166ZM33.6536 18.0833V33.5833H28.487V18.0833H33.6536Z"
        fill="#EDEDED"
      />
    </svg>
  )
}
