export const TooltipArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
      <g id="Frame 1321317290" clipPath="url(#clip0_586_19771)">
        <path
          id="Polygon 6"
          d="M0.578939 6.40853C0.296644 6.20933 0.296643 5.79067 0.578939 5.59147L12.7117 -2.97003C13.0429 -3.20375 13.5 -2.96688 13.5 -2.5615L13.5 14.5615C13.5 14.9669 13.0429 15.2038 12.7117 14.97L0.578939 6.40853Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_586_19771">
          <rect width="8" height="12" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
