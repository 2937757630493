export function LoginIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
        d="M15 3h5.68c.112 0 .168 0 .21.022a.2.2 0 01.088.087c.022.043.022.099.022.211v17.36c0 .112 0 .168-.022.21a.2.2 0 01-.087.088c-.043.022-.099.022-.211.022H15M9.14 7l5 5-5 5"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.14 12h11"
      ></path>
    </svg>
  )
}
