export const error = {
  notFound: {
    info: 'Sorry, the page cannot be found',
    description:
      'The page you are looking for does not exist<br/> or has been removed or deleted.<br/> For inquiries, please contact <strong/>docs_mgmt@nexon.co.kr.</strong>',
  },

  forbidden: {
    description: 'You do not have access <br/> to this menu and page.',
  },

  unauthorized: {
    description: 'Please sign in to use this menu and page.',
    button: 'Sign in',
  },
}
