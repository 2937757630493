import { useNavigate } from 'react-router-dom'

import useBuildT from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n'

import classes from './show-list-button.module.scss'

type ShowListButtonProps = {
  pathname: string
}

export const ShowListButton = ({ pathname }: ShowListButtonProps) => {
  const navigate = useNavigate()
  const t = useBuildT()

  const goToList = () => {
    navigate(pathname)
  }
  return (
    <button
      onClick={goToList}
      className={classes.button}
      data-testid="data-test-id-show-list-button"
    >
      <p data-testid="data-test-id-show-list-button-title" className={classes.text}>
        {t(i18nLanguage.common.navigation.backToList)}
      </p>
    </button>
  )
}
