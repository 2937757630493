import apiService from '@/configs/axios'
import {
  ServiceCategoryParams,
  ServiceCategoryResponse,
  ServiceDetailParams,
  ServiceDetailResponse,
  serviceCategoryEndpoints,
  serviceEndpoint,
} from '@/services/service'

export const serviceAPI = {
  getList(params: ServiceCategoryParams): Promise<ServiceCategoryResponse> {
    return apiService.get(serviceCategoryEndpoints.getList(), {
      params: {
        langType: params.langType,
        isIncludeService: params.isIncludeService ? 1 : 0,
      },
    })
  },
  getDetail(params: ServiceDetailParams): Promise<ServiceDetailResponse> {
    return apiService.get(serviceEndpoint.getDetail(params.id), {
      params: {
        langType: params.langType,
      },
    })
  },
}
