import { ComponentPropsWithoutRef } from 'react'

import clsx from 'clsx'

import styles from './skeleton.module.scss'

const Skeleton = ({ className, ...divProps }: ComponentPropsWithoutRef<'div'>) => {
  return <div className={clsx(styles.skeleton, className)} {...divProps} />
}

export default Skeleton
