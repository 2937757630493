import { FetchQueryOptions, UseQueryOptions, useQuery } from '@tanstack/react-query'

import { queryClient } from '@/configs/react-query'
import {
  ServiceCategoryParams,
  ServiceCategoryResponse,
  ServiceDetailParams,
  ServiceDetailResponse,
  serviceAPI,
  serviceCategoryKeys,
} from '@/services/service'

const useGetServiceCategories = <Data = ServiceCategoryResponse>(
  params: ServiceCategoryParams,
  options?: Omit<UseQueryOptions<ServiceCategoryResponse, unknown, Data>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery<ServiceCategoryResponse, unknown, Data>({
    queryKey: serviceCategoryKeys.withParams(params),
    queryFn: () => serviceAPI.getList(params),
    ...options,
  })
}

const useGetServiceDetail = <Data = ServiceDetailResponse>(
  params: ServiceDetailParams,
  options?: Omit<UseQueryOptions<ServiceDetailResponse, unknown, Data>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery<ServiceDetailResponse, unknown, Data>({
    queryKey: serviceCategoryKeys.withDetailParams(params),
    queryFn: () => serviceAPI.getDetail(params),
    ...options,
  })
}

const getServiceDetail = <Data = ServiceDetailResponse>(
  params: ServiceDetailParams,
  options?: Omit<FetchQueryOptions<ServiceDetailResponse, unknown, Data>, 'queryKey' | 'queryFn'>,
) => {
  return queryClient.fetchQuery<ServiceDetailResponse, unknown, Data>({
    queryKey: serviceCategoryKeys.withDetailParams(params),
    queryFn: () => serviceAPI.getDetail(params),
    ...options,
  })
}

export { useGetServiceCategories, useGetServiceDetail, getServiceDetail }
