export const search = {
  hasResult: "Total <highlight>{{total}}</highlight> result(s) found for '{{keyword}}'",
  beforeLogin: 'Some content may be available after signing in.',
  total: 'All',
  service: 'Service',
  document: 'Documentation',
  announcement: 'Announcement',
  news: 'News',
  noResult: "Sorry, no search results found\n for <highlight>'{{keyword}}'</highlight>",
  checkSpell:
    'Please check the spelling and spacing of the search keyword, \nor try a different keyword.',
  goBack: 'Back to Previous Page',
}
