/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ENV } from '@/configs/env'
import { useLocale } from '@/hooks/use-locale'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import { DOCUMENT_WRAPPER_ID } from '@/pages/documents/configs'
import { useDocumentsContext } from '@/pages/documents/context'
import { sendPathToIframe } from '@/pages/documents/utils'
import { documentEndpoints } from '@/services/documents'

import { ANCHOR_PARAM } from '../../constants'
import styles from './render-markdown.module.scss'

type RenderMarkdownProps = {
  isResizing: boolean
}

const RenderMarkdown: FC<RenderMarkdownProps> = ({ isResizing }) => {
  const {
    iframeRef,
    isMarkdownChanged,
    setIsMarkdownChanged,
    tableOfContentData,
    markdownContentError,
  } = useDocumentsContext()
  const lang = useLocale()
  const isLargeDesktop = useMediaQuery(mediaQueryBreakpoints.up('lg'))
  const [searchParams] = useSearchParams()
  const path = searchParams.get('path')

  useEffect(() => {
    const iframe = iframeRef.current
    if (iframe) {
      const message = {
        type: 'onChangeSize',
        data: isLargeDesktop,
      }
      iframe.contentWindow?.postMessage(message, window.location.origin)
    }
  }, [isLargeDesktop, isMarkdownChanged])

  useEffect(() => {
    const iframe = iframeRef.current
    if (iframe && path) sendPathToIframe(iframe, path, lang, setIsMarkdownChanged)
  }, [path, lang])

  useEffect(() => {
    const iframe = iframeRef.current
    if (!iframe) return
    const handleClick = (event: MouseEvent) => {
      const markdownSidebarMobile = document.getElementById('markdown-sidebar-mobile')
      const mainContent = document.getElementById('main-content')
      const scrollY = (event.target as HTMLElement)?.getBoundingClientRect().top
      window.scrollTo({
        top: scrollY + (mainContent?.offsetTop ?? 0) - (markdownSidebarMobile?.offsetHeight ?? 0),
        behavior: 'smooth',
      })
    }
    const elements =
      iframe.contentWindow?.document.querySelectorAll<HTMLElement>('h1, h2, h3, h4, h5')
    elements?.forEach((element) => {
      element.addEventListener('click', handleClick)
    })
    return () => {
      elements?.forEach((element) => {
        element.removeEventListener('click', handleClick)
      })
    }
  }, [tableOfContentData])

  useEffect(() => {
    const iframe = iframeRef.current
    if (!iframe) return
    const aTags = [
      ...(iframe.contentWindow?.document.querySelectorAll('a[anchorLink="true"]') ?? []),
    ] as HTMLLinkElement[]

    const handleClickAnchorLink = (event: MouseEvent) => {
      event.preventDefault()
      const a = event.currentTarget as HTMLLinkElement
      if (event.ctrlKey || event.metaKey) {
        window.open(a.href)
        return
      }
      const id = decodeURIComponent(a.href.split(ANCHOR_PARAM)[1])
      const element = iframe.contentWindow?.document.getElementById(id)
      element?.scrollIntoView({ behavior: 'smooth' })
    }

    aTags.forEach((aTag) => {
      aTag.href = aTag.href
        .replace(/^.*?(?=#)/, window.location.href.split(ANCHOR_PARAM)[0])
        .replace('#', ANCHOR_PARAM)
      aTag.addEventListener('click', handleClickAnchorLink)
    })

    return () => {
      aTags.forEach((aTag) => {
        aTag.removeEventListener('click', handleClickAnchorLink)
      })
    }
  }, [isMarkdownChanged])

  return (
    <iframe
      title="document"
      id={DOCUMENT_WRAPPER_ID}
      ref={iframeRef}
      className={styles['iframe']}
      src={
        ENV.ENABLE_FAKE_DATA === 'true'
          ? '/mockupHtml/index.html'
          : documentEndpoints.markdownSource()
      }
      // src={'/markdown'}
      scrolling="no"
      style={{
        pointerEvents: isResizing ? 'none' : 'auto',
        height: markdownContentError.error ? '0px' : undefined,
      }}
    />
  )
}

export default RenderMarkdown
