import { useTranslation } from 'react-i18next'

import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'

import { Button } from '@/components/button'
import { ArrowMenuIcon, TooltipArrowIcon } from '@/components/icons'
import { headerHeights } from '@/configs/common'
import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'
import { useFloatingHeaderMenu } from '@/layout/hooks'
import { HeaderMenuItemLv2 } from '@/layout/types'

import classes from './menu-item.module.scss'

const MENU_Y_AXIS_SPACING = 20
const CONTAINER_RIGHT_PADDING = 19

type HeaderDesktopMenuItemProps = {
  serviceCategory: HeaderMenuItemLv2
  handleRedirect: (link?: string) => void
}

export const HeaderDesktopMenuItem = ({
  serviceCategory,
  handleRedirect,
}: HeaderDesktopMenuItemProps) => {
  const { t } = useTranslation()
  const isLargeDesktop = useMediaQuery(mediaQueryBreakpoints.up('xl'))

  const floatingTopSpacing = isLargeDesktop ? headerHeights.DESKTOP : headerHeights.TABLET

  const {
    getReferenceProps,
    getFloatingProps,
    refs,
    floatingStyles,
    arrowTopPosition,
    context,
    isOpen,
    arrowRef,
    setIsOpen,
  } = useFloatingHeaderMenu({
    spacing: {
      top: floatingTopSpacing + MENU_Y_AXIS_SPACING,
      bottom: MENU_Y_AXIS_SPACING,
      left: CONTAINER_RIGHT_PADDING,
    },
  })

  const { label, services } = serviceCategory
  const hasServices = services ? services.length > 0 : false
  const showFloatingMenu = isOpen && hasServices
  return (
    <div
      data-testid="header-service-category-item-wrapper"
      className={classes['list-service-category-item']}
    >
      <Button
        data-testid="header-service-category-item"
        ref={refs.setReference}
        {...getReferenceProps()}
        className={classes['service-category-item']}
      >
        <span className={classes['item-label']}>{t(label)}</span>
        <div className={classes['more-btn']}>
          <ArrowMenuIcon />
        </div>
      </Button>

      {showFloatingMenu && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              data-testid="header-menu-floating-list"
              id="floating-header-menu-list"
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
              }}
              {...getFloatingProps()}
              className={classes['header-service-list-wrapper']}
            >
              <div className={classes['header-service-list']}>
                {services?.map((service) => (
                  <Button
                    data-testid="header-menu-floating-list-item"
                    key={`service-${service.id}`}
                    className={classes['header-service-list-item']}
                    onClick={() => {
                      handleRedirect(service.link)
                      setIsOpen(false)
                    }}
                  >
                    {service.label}
                  </Button>
                ))}
              </div>

              <div
                data-testid="header-menu-floating-icon"
                ref={arrowRef}
                className={classes['arrow-icon']}
                style={{
                  top: arrowTopPosition,
                }}
              >
                <TooltipArrowIcon />
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  )
}
