export const meta = {
  main: {
    title: 'GameScale Developers',
    ogTitle: 'GameScale Developers',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
  serviceOverview: {
    title: 'GameScale Developers | 서비스 리스트',
    ogTitle: 'GameScale Developers | 서비스 리스트',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
  serviceDetail: {
    title: 'GameScale Developers | {{detail}}',
    ogTitle: 'GameScale Developers | {{detail}}',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
  documentation: {
    title: 'GameScale Developers | {{detail}}',
    ogTitle: 'GameScale Developers | {{detail}}',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
  announcementList: {
    title: 'GameScale Developers | 공지사항',
    ogTitle: 'GameScale Developers | 공지사항',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
  announcementDetail: {
    title: 'GameScale Developers | {{detail}}',
    ogTitle: 'GameScale Developers | {{detail}}',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
  newsList: {
    title: 'GameScale Developers | 뉴스',
    ogTitle: 'GameScale Developers | 뉴스',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
  newsDetail: {
    title: 'GameScale Developers | {{detail}}',
    ogTitle: 'GameScale Developers | {{detail}}',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
  searchResult: {
    title: 'GameScale Developers | 검색',
    ogTitle: 'GameScale Developers | 검색',
    description:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
    ogDescription:
      'GameScale 서비스 소개와 기능 구현에 필요한 API와 개발 프로세스 문서를 제공합니다.',
  },
}
