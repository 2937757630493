import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { authAPIs } from '@/services/auth/auth.api'
import { authKeys } from '@/services/auth/auth.config'
import { MyInfoResponse } from '@/services/auth/auth.dto'

export const useGetMyInfo = (
  options?: Omit<UseQueryOptions<MyInfoResponse>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    queryKey: authKeys.myInfo,
    queryFn: () => {
      return authAPIs.myInfo()
    },
    ...options,
  })
}
