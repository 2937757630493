import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import clsx from 'clsx'

import useMediaQuery, { mediaQueryBreakpoints } from '@/hooks/use-media-query'

import classes from './button.module.scss'

type ButtonProps = ComponentPropsWithoutRef<'button'>

type Primitive = string | number | boolean | null | undefined | bigint | symbol
type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type ButtonSize = 'small' | 'medium' | 'large'
type ResponsiveProp<T> = {
  [key in Breakpoint]?: T
}

type StyledButtonProps = ComponentPropsWithoutRef<'button'> & {
  color?: 'primary' | 'secondary'
  fullWidth?: boolean | ResponsiveProp<boolean>
  size?: ButtonSize | ResponsiveProp<ButtonSize>
}

export const Button = forwardRef<ElementRef<'button'>, ButtonProps>(
  ({ children, className, ...buttonProps }, ref) => {
    return (
      <button ref={ref} className={clsx(classes['button'], className)} {...buttonProps}>
        {children}
      </button>
    )
  },
)

export const StyledButton = forwardRef<ElementRef<'button'>, StyledButtonProps>(
  (
    { children, className, fullWidth = false, color = 'primary', size = 'small', ...buttonProps },
    ref,
  ) => {
    const screens: Record<Breakpoint, boolean> = {
      xs: true,
      sm: useMediaQuery(mediaQueryBreakpoints.up('sm')),
      md: useMediaQuery(mediaQueryBreakpoints.up('md')),
      lg: useMediaQuery(mediaQueryBreakpoints.up('lg')),
      xl: useMediaQuery(mediaQueryBreakpoints.up('xl')),
    }

    const getResponsiveProp = <T extends Primitive>(
      prop: T | ResponsiveProp<T>,
      defaultValue: T,
    ): T => {
      if (typeof prop !== 'object' || prop === null) return prop

      const responsiveArray: Breakpoint[] = ['xl', 'lg', 'md', 'sm', 'xs']
      const curScreen = responsiveArray.find(
        (curScreen) => screens[curScreen] && prop[curScreen] !== undefined,
      )
      return curScreen ? (prop[curScreen] as T) : defaultValue
    }

    return (
      <Button
        ref={ref}
        className={clsx(
          classes['styled-button'],
          classes[color],
          classes[getResponsiveProp(size, 'small')],
          className,
          {
            [classes['full-width']]: getResponsiveProp(fullWidth, false),
          },
        )}
        {...buttonProps}
      >
        {children}
      </Button>
    )
  },
)

if (import.meta.env.DEV) {
  Button.displayName = 'Button'
  StyledButton.displayName = 'StyledButton'
}
