import { useTranslation } from 'react-i18next'

import { AlertIcon } from '@/components/icons/alert-icon'
import { i18nLanguage } from '@/i18n'

import styles from './forbidden.module.scss'

export const ForbiddenPage = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <AlertIcon className={styles.icon} />
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: t(i18nLanguage.error.forbidden.description) }}
      />
    </div>
  )
}
