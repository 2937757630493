/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, FC, ReactNode, SetStateAction, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { EErrorCode } from '@/enums'
import { useLocale } from '@/hooks/use-locale'
import { TContent } from '@/pages/documents/components/table-of-content'
import { useDocumentsContext } from '@/pages/documents/context'
import {
  EventListenerType,
  TClickTagAData,
  TMarkdownContentError,
  TMessage,
} from '@/pages/documents/types'
import { iframeResizer, sendPathToIframe } from '@/pages/documents/utils'
import { authAPIs } from '@/services/auth/auth.api'
import { backToTop } from '@/utils/back-to-top'

import styles from './markdown-layout.module.scss'

const setPureHeight = () => {
  const sidebar = document.querySelector<HTMLDivElement>('.react-resizable')
  if (sidebar) sidebar.style.maxHeight = 'calc(100vh - var(--header-height) - var(--footer-height))'
}

const MarkdownLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    setTableOfContentData,
    setIsMarkdownChanged,
    iframeRef,
    setMarkdownContentError,
    isMarkdownChanged,
    setErrorCode,
    setIsMarkdownInitialized,
  } = useDocumentsContext()

  const lang = useLocale()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const path = params.get('path')
  const loadingIframe = () => {
    iframeResizer()
    setIsMarkdownChanged(true)
  }

  const handleNotFound = (type: TMarkdownContentError) => {
    setPureHeight()
    setMarkdownContentError({ error: true, type })
  }

  const handleForbidden = () => {
    setErrorCode(EErrorCode.FORBIDDEN)
  }

  const handleMarkdownFound = () => {
    setMarkdownContentError((prev) => ({
      ...prev,
      error: false,
    }))
  }

  const handleTOCData = (
    setTableOfContentData: Dispatch<SetStateAction<TContent[]>>,
    data: TContent[] | undefined,
  ) => {
    if (data) setTableOfContentData(data)
  }

  const handleUnauthorized = async () => {
    try {
      await authAPIs.refreshToken()
      const iframe = iframeRef.current
      if (iframe) {
        iframe.contentWindow?.postMessage(
          { type: 'refreshToken', data: null },
          window.location.origin,
        )
      }
    } catch (refreshError) {
      setErrorCode(EErrorCode.UNAUTHORIZED)
    }
  }

  const handleInitMarkdown = () => {
    const iframe = iframeRef.current
    if (iframe) {
      setIsMarkdownInitialized(true)
      if (path) sendPathToIframe(iframe, path, lang, setIsMarkdownChanged)
    }
  }

  const handleClickTagA = ({ href, isPressCtrl }: TClickTagAData) => {
    if (isPressCtrl) window.open(href, '_blank')
    else navigate(href)
  }

  const eventListener: EventListenerType = {
    onChangeMarkdownContent: loadingIframe,
    contentChangeHeight: iframeResizer,
    unauthorized: handleUnauthorized,
    notFound: () => handleNotFound('notFound'),
    badRequest: () => handleNotFound('badRequest'),
    forbidden: () => handleForbidden(),
    markdownFound: handleMarkdownFound,
    initMarkdown: handleInitMarkdown,
    TOC: handleTOCData,
    clickTagA: handleClickTagA,
  }

  useEffect(() => {
    const handleEventComing = (msg: MessageEvent<TMessage>) => {
      if (msg.data.type === 'TOC') {
        eventListener[msg.data.type]?.(setTableOfContentData, JSON.parse(msg.data.data))
      } else if (msg.data.type === 'clickTagA') {
        eventListener[msg.data.type]?.(msg.data.data)
      } else {
        eventListener[msg.data.type]?.(msg.data.type as TMarkdownContentError)
      }
    }
    window.addEventListener('message', handleEventComing)
    return () => {
      window.removeEventListener('message', handleEventComing)
    }
  }, [])

  useEffect(() => {
    setTableOfContentData([])
    setMarkdownContentError({ error: false, type: null })
    backToTop()
  }, [path])

  return (
    <div className={styles['iframe-wrapper']} id="iframe-wrapper">
      {!isMarkdownChanged && <div className={styles['loading-iframe']}></div>}
      {children}
    </div>
  )
}

export default MarkdownLayout
