export function ChevronRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50879 4.05037L10.1755 8.71703L5.50879 13.3837L6.2159 14.0908L11.5897 8.71704L6.2159 3.34326L5.50879 4.05037Z"
        fill="currentColor"
      />
    </svg>
  )
}
