import { LANGUAGES } from '@/configs/common'

export const LANGUAGE_REGEX = new RegExp(`/(\\b(?:${Object.values(LANGUAGES).join('|')})\\b)`)

export const removeLanguageFromUrl = (url: string) => {
  return url.replace(LANGUAGE_REGEX, '')
}

export const getUrlWithLanguage = (pathname: string, lang: string) => {
  const arrayPath = pathname.split('/')
  arrayPath[1] = lang

  return arrayPath.join('/')
}
