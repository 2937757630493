import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { EventEmitter } from 'events'

import Skeleton from '@/components/skeleton'
import { EErrorCode } from '@/enums'
import { useLocale } from '@/hooks/use-locale'
import { i18nLanguage } from '@/i18n'
import { RenderChildren } from '@/pages/documents/components/document-directory/render-children/render-children'
import { PARENT_NAME_PRIVATE_DOCUMENTS } from '@/pages/documents/configs'
import { useDocumentsContext } from '@/pages/documents/context'
import { formatBreadcrumbs } from '@/pages/documents/utils'
import { useGetMyInfo } from '@/services/auth/auth.hook'
import { EPermissionView, NestedDocumentDTO, useGetDocumentsNestedTree } from '@/services/documents'

import styles from './document-directory.module.scss'

export const eventEmitter = new EventEmitter()

export const DocumentDirectory = () => {
  const { t } = useTranslation()
  const lang = useLocale()
  const [searchParams] = useSearchParams()
  const directoryRef = useRef<HTMLDivElement>(null)
  const directoryWrapperRef = useRef<HTMLDivElement>(null)
  const pathFromUrl = searchParams.get('path')
  const { breadcrumbs, setBreadcrumbs } = useDocumentsContext()
  const { setErrorCode } = useDocumentsContext()
  const { data: userInfo } = useGetMyInfo()
  const { data: dataPublic, isPending: isPendingPublic } = useGetDocumentsNestedTree(
    {
      langType: lang,
      permissionView: EPermissionView.public,
    },
    {
      enabled: !!userInfo?.data,
    },
  )
  const { data: dataPrivate, isLoading: isLoadingPrivate } = useGetDocumentsNestedTree(
    {
      langType: lang,
      permissionView: EPermissionView.private,
    },
    {
      enabled: !!userInfo?.data,
    },
  )
  useEffect(() => {
    if (!pathFromUrl) return
    if (!dataPrivate?.data) {
      setBreadcrumbs(formatBreadcrumbs(pathFromUrl, dataPublic?.data || []))
      return
    }
    setBreadcrumbs(formatBreadcrumbs(pathFromUrl, dataPublic?.data.concat(dataPrivate?.data) || []))
  }, [pathFromUrl, dataPrivate, dataPublic])
  const privateTree = useMemo<NestedDocumentDTO[]>(
    () => [
      {
        isFolder: true,
        children: dataPrivate?.data || [],
        langType: '',
        title: t(i18nLanguage.documents.restricted),
        markdownPath: '',
        modifiedDate: '',
        key: PARENT_NAME_PRIVATE_DOCUMENTS,
      },
    ],
    [dataPrivate?.data],
  )

  useEffect(() => {
    if (userInfo && !userInfo.data) {
      setErrorCode(EErrorCode.UNAUTHORIZED)
    }
  }, [userInfo])

  const handleScroll = useCallback((data: string) => {
    const element = document.getElementById(data)
    setTimeout(() => {
      const wrapper = document.getElementById('wrapper-directory')
      if (!wrapper) return
      const wrapperRect = wrapper.getBoundingClientRect()
      const elementRect = element?.getBoundingClientRect()
      if (!elementRect) return
      if (elementRect.top < wrapperRect.top || elementRect.bottom > wrapperRect.bottom) {
        const parentHeight = window.getComputedStyle(wrapper).height
        wrapper.scrollTo({
          top: elementRect.top - parseFloat(parentHeight) / 2,
          behavior: 'smooth',
        })
      }
    }, 500)
  }, [])

  useEffect(() => {
    eventEmitter.on('broadcast', handleScroll)

    return () => {
      eventEmitter.removeListener('broadcast', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    const directoryEl = directoryRef.current
    const directoryWrapperEl = directoryWrapperRef.current

    const handleWheel = (e: MouseEvent) => {
      if (directoryEl && directoryEl.getBoundingClientRect().height < window.innerHeight)
        e.preventDefault()
    }
    directoryWrapperEl?.addEventListener('wheel', handleWheel)

    return () => {
      directoryWrapperEl?.removeEventListener('wheel', handleWheel)
    }
  }, [dataPublic, privateTree])

  return (
    <div className={styles['wrapper-directory']} id="wrapper-directory" ref={directoryWrapperRef}>
      {isPendingPublic || (userInfo?.data && isLoadingPrivate) ? (
        <Skeleton className={styles.loading} />
      ) : (
        <div className={styles['document-directory']} ref={directoryRef}>
          <RenderChildren
            data={dataPublic?.data}
            recursionDepth={1}
            breadcrumbs={breadcrumbs}
            isPrivate={false}
          />
          {dataPrivate?.data && dataPrivate?.data.length > 0 && (
            <RenderChildren
              data={privateTree}
              recursionDepth={1}
              breadcrumbs={breadcrumbs}
              isPrivate={true}
            />
          )}
        </div>
      )}
    </div>
  )
}
export default DocumentDirectory
