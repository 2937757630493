/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react'
import { createBrowserRouter, redirect } from 'react-router-dom'

import { locales } from '@/configs/locales'
import { ROUTES } from '@/configs/paths'
import i18n from '@/i18n/i18n'
import MainLayout from '@/layout/main-layout'
import Documents from '@/pages/documents'
import ErrorPage from '@/pages/error'
import NewsDetailPage from '@/pages/support/news-list-detail-page/news-detail'
import { getAnnouncementByIdClient } from '@/services/announcement'
import { getNewsByNewsIdClient } from '@/services/news'
import { getServiceDetail } from '@/services/service'
import { Locale } from '@/types/locales'
import { userLang } from '@/utils/user-lang'

const MainPage = lazy(() => import('@/pages/main'))
const NewsListPage = lazy(() => import('@/pages/support/news-list-page'))
const NoticeDetailPage = lazy(() => import('@/pages/support/notice-detail-page'))
const NoticeListPage = lazy(() => import('@/pages/support/notice-list-page'))
const ServiceOverviewPage = lazy(() => import('@/pages/service/overview'))
const ServiceDetailPage = lazy(() => import('@/pages/service/detail'))
const SearchPage = lazy(() => import('@/pages/search/search'))

const router = () =>
  createBrowserRouter([
    {
      element: <MainLayout />,
      loader: ({ params }) => {
        if (params.lang) return null

        if (userLang().isKo) {
          return redirect(`/${locales.KO}`)
        }
        return redirect(`/${locales.EN}`)
      },
      errorElement: <ErrorPage />,
      children: [
        {
          path: ':lang',
          loader: ({ params }) => {
            if (params.lang === locales.KO || params.lang === locales.EN) {
              i18n.changeLanguage(params.lang)
              return null
            }
            return redirect(`/${locales.EN}`)
          },
          children: [
            {
              path: ROUTES.MAIN,
              element: <MainPage />,
            },
            {
              path: ROUTES.NOTICE_LIST,
              element: <NoticeListPage />,
            },
            {
              path: ROUTES.NOTICE_DETAIL,
              element: <NoticeDetailPage />,
              loader: async ({ params }) => {
                const { id, lang } = params
                const { data } = await getAnnouncementByIdClient({
                  id: Number(id),
                  langType: lang as Locale,
                })
                const detail = data?.detail
                if (!detail.announcement) {
                  throw new Response('Page Not Found', { status: 404 })
                }
                return data
              },
            },
            {
              path: ROUTES.NEWS_LIST,
              element: <NewsListPage />,
            },
            {
              path: ROUTES.NEWS_DETAIL,
              loader: async ({ params }) => {
                const { id, lang } = params
                const { data } = await getNewsByNewsIdClient(id as string, lang as Locale)
                const detail = data?.detail
                if (!detail.news) {
                  throw new Response('Page Not Found', { status: 404 })
                }
                return data
              },
              element: <NewsDetailPage />,
            },
            {
              path: ROUTES.SERVICE_OVERVIEW,
              element: <ServiceOverviewPage />,
            },
            {
              path: ROUTES.SERVICE_DETAIL,
              loader: async ({ params }) => {
                const { id, lang } = params

                await getServiceDetail({
                  id: Number(id),
                  langType: lang as Locale,
                })

                return null
              },
              element: <ServiceDetailPage />,
            },
            {
              path: ROUTES.SEARCH,
              element: <SearchPage />,
            },
            {
              path: ROUTES.DOCUMENT,
              element: <Documents />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      loader: ({ params }) => {
        if (params['*']) throw new Response('Page Not Found', { status: 404 })
        if (userLang().isKo) {
          return redirect(`/${locales.KO}`)
        }
        return redirect(`/${locales.EN}`)
      },
      errorElement: <ErrorPage />,
    },
  ])

export default router
