export const search = {
  hasResult: "'{{keyword}}'의 검색결과 총 <highlight>{{total}}</highlight>건",
  beforeLogin: '로그인 후 확인 가능한 내용이 있을 수 있습니다.',
  total: '전체',
  service: '서비스',
  document: '문서',
  announcement: '공지사항',
  news: '뉴스',
  noResult: "<highlight>'{{keyword}}'</highlight>에 대한 검색 결과가 없습니다.",
  checkSpell: '검색어의 철자와 띄어쓰기가 정확한지 확인해 보시거나\n 다른 검색어를 입력해보세요.',
  goBack: '이전 페이지로 돌아가기',
}
