export const CloseIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0093 13.8233L2.20927 3.02327L3.19922 2.03333L13.9992 12.8333L13.0093 13.8233Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99073 13.8233L13.7907 3.02327L12.8008 2.03333L2.00078 12.8333L2.99073 13.8233Z"
      fill="black"
    />
  </svg>
)
