import { useTranslation } from 'react-i18next'

import { AlertIcon } from '@/components/icons/alert-icon'
import { i18nLanguage } from '@/i18n'
import { authAPIs } from '@/services/auth/auth.api'

import styles from './unauthorize.module.scss'

export const UnauthorizedPage = () => {
  const { t } = useTranslation()

  const redirectLogin = () => {
    authAPIs.redirectLogin()
  }

  return (
    <div className={styles.container}>
      <AlertIcon className={styles.icon} />
      <h1 className={styles.info}>{t(i18nLanguage.error.unauthorized.description)}</h1>
      <button className={styles.btn} onClick={redirectLogin}>
        {t(i18nLanguage.error.unauthorized.button)}
      </button>
    </div>
  )
}
