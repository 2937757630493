import { PropsWithChildren } from 'react'

import { HeaderProvider } from '@/providers/header-provider'
import HelmetProvider from '@/providers/helmet-provider'
import { LanguageSwitchProvider } from '@/providers/language-switch-provider'

import QueryProvider from './query-provider'

function AppProvider({ children }: PropsWithChildren) {
  return (
    <HelmetProvider>
      <QueryProvider>
        <LanguageSwitchProvider>
          <HeaderProvider>{children}</HeaderProvider>
        </LanguageSwitchProvider>
      </QueryProvider>
    </HelmetProvider>
  )
}

export default AppProvider
