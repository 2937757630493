import { FetchQueryOptions, UseQueryOptions, useQuery } from '@tanstack/react-query'

import { queryClient } from '@/configs/react-query'
import { newsAPI } from '@/services/news/news.api'
import { newsKeys } from '@/services/news/news.config'
import { Locale } from '@/types/locales'

import {
  TGetNewsListParams,
  TNewsDetailResponse,
  TNewsListResponse,
  TNewsParams,
} from './news.type'

const useGetMainNewsList = (
  params: TNewsParams,
  options?: Omit<UseQueryOptions<TNewsListResponse>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: newsKeys.mainNewsWithParams(params),
    queryFn: () => newsAPI.getMainNewsList(params),
    ...options,
  })

const useGetNewsHeroList = (
  lang: Locale,
  options?: Omit<UseQueryOptions<TNewsListResponse>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: newsKeys.newsHeroList(lang),
    queryFn: () => newsAPI.getNewsHeroList(lang),
    ...options,
  })

const useGetNewsByNewsId = (
  id: string,
  lang: Locale,
  options?: Omit<UseQueryOptions<TNewsDetailResponse>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: newsKeys.newsById(id, lang),
    queryFn: () => newsAPI.getNewsByNewsId(id, lang),
    ...options,
  })

const getNewsByNewsIdClient = (
  id: string,
  lang: Locale,
  options?: Omit<FetchQueryOptions<TNewsDetailResponse>, 'queryKey' | 'queryFn'>,
) =>
  queryClient.fetchQuery({
    queryKey: newsKeys.newsById(id, lang),
    queryFn: () => newsAPI.getNewsByNewsId(id, lang),
    ...options,
  })

const useGetNewsList = (
  params: TGetNewsListParams,
  options?: Omit<UseQueryOptions<TNewsListResponse>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    queryKey: newsKeys.newsList(params),
    queryFn: () => newsAPI.getNewsList(params),
    ...options,
  })

export {
  getNewsByNewsIdClient,
  useGetMainNewsList,
  useGetNewsByNewsId,
  useGetNewsHeroList,
  useGetNewsList,
}
