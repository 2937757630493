import { SVGProps } from 'react'

export function MenuIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.45 13.05H2.55v-2.1h18.9v2.1zM21.45 5.175H2.55v-2.1h18.9v2.1zM21.45 20.925H2.55v-2.1h18.9v2.1z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
