import { headerActionTypes } from '@/providers/header-provider/configs'
import { HeaderActions, HeaderState } from '@/providers/header-provider/types'

export const headerReducer = (state: HeaderState, action: HeaderActions): HeaderState => {
  switch (action.type) {
    case headerActionTypes.openSearchBar:
      return { ...state, isSearchBarOpen: true }
    case headerActionTypes.closeSearchBar:
      return { ...state, isSearchBarOpen: false }
    case headerActionTypes.openHeaderMenu:
      return { ...state, isMenuOpen: true }
    case headerActionTypes.closeHeaderMenu:
      return { ...state, isMenuOpen: false }
    case headerActionTypes.toggleHeaderMenu:
      if (!state.isMenuOpen) {
        state.activeMenu = null
        state.activeSubmenu = null
      }
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      }
    case headerActionTypes.resetHeaderMenu:
      return { ...state, activeMenu: null, isMenuOpen: false }
    case headerActionTypes.setHeaderMenuId:
      return { ...state, activeMenu: action.payload.menuId, isMenuOpen: true }
    case headerActionTypes.resetHeaderMenuId:
      return { ...state, activeMenu: null }
    case headerActionTypes.setHeaderSubMenuId:
      return { ...state, activeSubmenu: action.payload.submenuId }
    case headerActionTypes.resetHeaderSubMenuId:
      return { ...state, activeSubmenu: null }
  }
}
