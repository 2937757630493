export function ArrowMenuIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g id="ico_ArrowGNB">
        <path
          id="Vector 28"
          d="M6.99219 2.00195L14.9922 10.002L6.99219 18.002"
          stroke="currentColor"
          strokeWidth="2.5"
        />
      </g>
    </svg>
  )
}
