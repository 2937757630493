import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import clsx from 'clsx'

import { RouterLink } from '@/components/router-link'
import { ROUTES } from '@/configs/paths'
import { i18nLanguage } from '@/i18n'
import { removeLanguageFromUrl } from '@/utils/language-url'

import classes from './footer.module.scss'
import { LanguageSwitcher } from './language-switcher'

const i18n = i18nLanguage.common

function Footer() {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const currentRoute = removeLanguageFromUrl(pathname)

  const isMainPage = currentRoute === ROUTES.MAIN

  return (
    <footer
      className={clsx(classes['footer'], {
        [classes['main-bg']]: isMainPage,
      })}
    >
      <p data-testid="footer-copyright" className={classes['copyright']}>
        {t(i18n.footer)}
      </p>
      <div data-testid="footer-control" className={classes['footer-control']}>
        <RouterLink
          data-testid="footer-policy-link"
          href="https://nxas.nexon.com/PrivacyPolicy"
          className={classes['redirect-link']}
          isExternal
        >
          {t(i18n.privacyPolicy)}
        </RouterLink>
        <LanguageSwitcher />
      </div>
    </footer>
  )
}

export default Footer
