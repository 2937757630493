import { FetchQueryOptions, UseQueryOptions, useQuery } from '@tanstack/react-query'

import { queryClient } from '@/configs/react-query'

import { announcementAPI } from './announcement.api'
import { announcementKeys } from './announcement.config'
import { AnnouncementDetailParams, AnnouncementParams } from './announcement.dto'
import { AnnouncementDetailResponse, AnnouncementResponse } from './announcement.type'

export function useAnnouncementList<Data = AnnouncementResponse>(
  params: AnnouncementParams,
  options?: Omit<UseQueryOptions<AnnouncementResponse, unknown, Data>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<AnnouncementResponse, unknown, Data>({
    queryKey: announcementKeys.listWithParams(params),
    queryFn: () => announcementAPI.getList(params),
    ...options,
  })
}

export function useAnnouncementDetail<Data = AnnouncementDetailResponse>(
  params: AnnouncementDetailParams,
  options?: Omit<
    UseQueryOptions<AnnouncementDetailResponse, unknown, Data>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<AnnouncementDetailResponse, unknown, Data>({
    queryKey: announcementKeys.detailWithParams(params),
    queryFn: () => announcementAPI.getDetail(params),
    ...options,
  })
}

export const getAnnouncementByIdClient = (
  params: AnnouncementDetailParams,
  options?: Omit<FetchQueryOptions<AnnouncementDetailResponse>, 'queryKey' | 'queryFn'>,
) =>
  queryClient.fetchQuery({
    queryKey: announcementKeys.detailWithParams(params),
    queryFn: () => announcementAPI.getDetail(params),
    ...options,
  })
