import { common } from './common'
import { documents } from './documents'
import { error } from './error'
import { header } from './header'
import { main } from './main'
import { meta } from './meta'
import { search } from './search'
import { service } from './service'
import { support } from './support'

const ko = {
  meta,
  header,
  main,
  common,
  support,
  service,
  search,
  error,
  documents,
}

export default ko
