import { Dispatch, SetStateAction } from 'react'

import { NestedDocumentDTO } from '@/services/documents'

export interface ResultFormatBreadcrumbs {
  title: string
  key: string
}

const formatPathParts = (path: string) => {
  const parts = path.split('/').filter(Boolean)
  // check duplicate at the end of the path
  if (parts[parts.length - 1] === parts[parts.length - 2]) {
    parts.pop()
  } else {
    // add .md to the last part
    const lastPart = parts[parts.length - 1] + '.md'
    parts[parts.length - 1] = lastPart
  }
  return parts
}

export const formatBreadcrumbs = (
  path: string,
  dataDocuments: NestedDocumentDTO[],
): ResultFormatBreadcrumbs[] => {
  const parts = formatPathParts(path)
  const result: ResultFormatBreadcrumbs[] = []
  let currentPath = ''

  const documentMap = new Map<string, ResultFormatBreadcrumbs>()
  const flatten = (doc: NestedDocumentDTO) => {
    documentMap.set(doc.key, { title: doc.title, key: doc.key })
    if (doc.children.length > 0) {
      doc.children.forEach(flatten)
    }
  }

  dataDocuments.forEach(flatten)

  for (const part of parts) {
    currentPath += (currentPath ? '/' : '') + part
    const objBreadcrumb = documentMap.get(currentPath)
    result.push(objBreadcrumb || { title: part, key: part })
  }
  return result
}

export const getNewPathMarkdown = (path: string, lang: string): string => {
  const pathPart = path?.split('/')
  pathPart?.splice(1, 0, lang)
  return pathPart?.join('/') + '.md'
}

export const iframeResizer = () => {
  const iframe = document.querySelector<HTMLIFrameElement>('#document-wrapper')
  const sidebar = document.querySelector<HTMLDivElement>('.react-resizable')
  const footer = document.querySelector<HTMLElement>('footer')
  if (!iframe) return
  // Reset and set new height for the wrapper
  const iframeHeight =
    iframe.contentWindow?.document.getElementById('markdown-content')?.scrollHeight
  iframe.height = `${iframeHeight}px`
  if (sidebar) {
    if (footer) {
      let spacing = footer.getBoundingClientRect().top - window.innerHeight
      if (spacing < 0) {
        spacing = Math.abs(spacing)
        sidebar.style.maxHeight = `calc(100vh - var(--header-height) - ${spacing}px)`
      } else {
        const isIframeTallerThanWindow = document.body.scrollHeight > window.innerHeight
        sidebar.style.maxHeight = isIframeTallerThanWindow
          ? 'calc(100vh - var(--header-height))'
          : 'calc(100vh - var(--header-height) - var(--footer-height))'
      }
    } else {
      const isIframeTallerThanWindow = document.body.scrollHeight > window.innerHeight
      sidebar.style.maxHeight = isIframeTallerThanWindow
        ? 'calc(100vh - var(--header-height))'
        : 'calc(100vh - var(--header-height) - var(--footer-height))'
    }
  }
}

export const sendPathToIframe = (
  iframe: HTMLIFrameElement,
  path: string,
  lang: string,
  setIsMarkdownChanged?: Dispatch<SetStateAction<boolean>>,
) => {
  const message = {
    type: 'onChangePath',
    path: getNewPathMarkdown(path, lang),
  }
  if (!iframe) return
  iframe.contentWindow?.postMessage(message, window.location.origin)
  setIsMarkdownChanged && setIsMarkdownChanged(false)
}
