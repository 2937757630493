import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

type LanguageSwitchContextValue = {
  isShowMenu: boolean
  setIsShowMenu: Dispatch<SetStateAction<boolean>>
}

const LanguageSwitchContext = createContext<LanguageSwitchContextValue | null>(null)

export const LanguageSwitchProvider = ({ children }: PropsWithChildren) => {
  const [isShowMenu, setIsShowMenu] = useState(false)

  const values = useMemo(() => ({ isShowMenu, setIsShowMenu }), [isShowMenu])

  return <LanguageSwitchContext.Provider value={values}>{children}</LanguageSwitchContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export function useLanguageSwitchContext() {
  const context = useContext(LanguageSwitchContext)

  if (!context)
    throw new Error('useLanguageSwitchContext must be used within a LanguageSwitchProvider')
  return context
}
