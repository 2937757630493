import { ElementRef, useRef } from 'react'

import clsx from 'clsx'
import useResizeObserver from 'use-resize-observer'

import { ScrollToTopIcon } from '@/components/icons'
import useBoolean from '@/hooks/use-boolean'
import useEventListener from '@/hooks/use-event-listener'
import { useLanguageSwitchContext } from '@/providers/language-switch-provider'

import { TOP_POSITION } from './scroll-to-top-button.config'
import { scrollToTop } from './scroll-to-top-button.helper'
import styles from './scroll-to-top-button.module.scss'
import { ScrollToTopButtonProps } from './scroll-to-top-button.type'

const REMOVE_SCROLLBAR_PROPERTY_NAME = '--removed-body-scroll-bar-size'

function ScrollToTopButton({
  top = TOP_POSITION,
  isSmooth = true,
  ...passProps
}: ScrollToTopButtonProps) {
  const btnRef = useRef<ElementRef<'button'>>(null)
  const isVisible = useBoolean(false)
  const { isShowMenu, setIsShowMenu } = useLanguageSwitchContext()

  const updateScrollToTopPosition = () => {
    const footer = document.querySelector<HTMLDivElement>('footer')

    if (!footer || !btnRef.current) return
    const footerTop = footer.getBoundingClientRect().top
    const footerBottom = footer.getBoundingClientRect().bottom

    if (footerTop > window.innerHeight) {
      btnRef.current.style.position = 'fixed'
      btnRef.current.style.removeProperty(REMOVE_SCROLLBAR_PROPERTY_NAME)
    } else {
      btnRef.current.style.position = 'absolute'
      btnRef.current.style.setProperty(REMOVE_SCROLLBAR_PROPERTY_NAME, '0px')
    }

    if (footerBottom > window.innerHeight && isShowMenu) setIsShowMenu(false)
  }

  const handleScroll = () => {
    const isTopPosition = document.documentElement.scrollTop >= top
    isVisible.setValue(isTopPosition)
    updateScrollToTopPosition()
  }

  useResizeObserver({
    ref: document.documentElement,
    onResize: updateScrollToTopPosition,
  })

  useEventListener({
    eventType: 'scroll',
    handler: handleScroll,
  })

  const handleScrollToTop = () => {
    scrollToTop(isSmooth)
    isVisible.setFalse()
  }

  return (
    <button
      data-testid="scroll-to-top-btn"
      ref={btnRef}
      className={clsx(styles['scroll-to-top-btn'], {
        [styles.active]: isVisible.value && !isShowMenu,
      })}
      onClick={handleScrollToTop}
      {...passProps}
    >
      <ScrollToTopIcon className={styles.icon} />
    </button>
  )
}

export default ScrollToTopButton
