import { LOGOUT_URL, apiVersion } from '@/configs/common'

export const authEndpoints = {
  login: (originalUrl = window.location.href) =>
    `/auth/login?originalUrl=${encodeURIComponent(originalUrl)}`,
  logout: () =>
    `${LOGOUT_URL}/logout?redirect_uri=${window.location.origin}${apiVersion.v1}/auth/logout`,
  myInfo: '/auth/my-info',
  refreshToken: '/auth/refresh-token',
}

export const authKeys = {
  myInfo: ['auth', 'my-info'],
}
