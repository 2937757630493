export const support = {
  noticeList: {
    table: {
      header: {
        title: '제목',
        date: '작성일',
      },
    },
  },
  newsDetail: {
    toastMessage: '링크가 복사되었습니다.',
  },
}
