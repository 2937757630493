import { CSSProperties, ElementRef, useCallback, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import { TContent } from '@/pages/documents/components/table-of-content'
import { useDocumentsContext } from '@/pages/documents/context'

import styles from './table-of-content-item.module.scss'

interface ITableOfContentItemProps {
  name: string
  id: string
  data: TContent[]
  level?: number
  activeId?: string
}

const TableOfContentItem = ({ name, id, data, level = 0, activeId }: ITableOfContentItemProps) => {
  const { iframeRef } = useDocumentsContext()
  const [searchParams] = useSearchParams()
  const anchor = searchParams.get('anchor')
  const itemRef = useRef<ElementRef<'p'>>(null)
  const navigate = useNavigate()

  const style = {
    '--level-item': level,
    marginTop: level > 0 && '4px',
    paddingLeft: `${!level && 0}px`,
  } as CSSProperties

  const isActiveItem = activeId === id
  const scrollIntoView = () => {
    const element = iframeRef.current?.contentWindow?.document.getElementById(id)
    const mainContent = document.getElementById('main-content')
    if (!element) return
    const scrollY = element?.getBoundingClientRect().top
    window.scrollTo({
      top: scrollY + (mainContent?.offsetTop ?? 0),
      behavior: 'smooth',
    })
  }
  const handleClick = useCallback(() => {
    const newParams = new URLSearchParams(searchParams.toString())
    newParams.set('anchor', id)
    const newUrl = `${window.location.pathname}?${decodeURIComponent(newParams.toString())}`
    navigate(newUrl, { replace: true })
    scrollIntoView()
  }, [id, iframeRef])

  useEffect(() => {
    const handleScrollIntoView = () => {
      const parent = document.getElementById('table-of-content-main')

      if (parent && itemRef.current && isActiveItem) {
        const parentHeight = window.getComputedStyle(parent).height
        parent.scrollTo({
          //* center the active item in the middle of the parent container when scrolling
          top: itemRef.current.offsetTop - parseFloat(parentHeight) / 2,
          behavior: 'smooth',
        })
      }
    }

    window.addEventListener('scroll', handleScrollIntoView)
    return () => {
      window.removeEventListener('scroll', handleScrollIntoView)
    }
  }, [isActiveItem])

  useEffect(() => {
    if (anchor === id) {
      scrollIntoView()
    }
  }, [anchor])

  return (
    <div className={clsx(styles['table-of-content-item'])} style={style}>
      <p
        ref={itemRef}
        className={clsx(styles['title'], {
          [styles['title-no-data']]: !data?.length && level !== 0,
          [styles['active']]: isActiveItem,
        })}
        onClick={handleClick}
        aria-hidden
        id={id}
      >
        {name}
      </p>
      {data && (
        <div className={styles['children']}>
          {data.map((item) => {
            return (
              <TableOfContentItem
                key={item.id}
                name={item.name}
                id={item.id}
                data={item.children}
                level={level + 1}
                activeId={activeId}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
export default TableOfContentItem
