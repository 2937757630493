import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { i18nLanguage } from '@/i18n/i18n'

import RenderDocumentPage from './components/breadcrumb-document/render-document-page'
import { DocumentsProvider } from './context'

type EventListener = {
  type: 'getMetaTags'
  data: string
}

type MetaTags = {
  title: string
  lang: string
  meta: Record<string, string>[]
}

const Documents = () => {
  const [metaTags, setMetaTags] = useState<MetaTags>({
    title: '',
    lang: '',
    meta: [],
  })
  useEffect(() => {
    const handleEventComing = (msg: MessageEvent<EventListener>) => {
      if (msg.data.type === 'getMetaTags') {
        const { title, lang, meta } = JSON.parse(msg.data.data) as MetaTags
        setMetaTags({
          title: title ? title : '',
          lang,
          meta,
        })
      }
    }
    const html = document.querySelector('html')
    if (html) html.style.scrollBehavior = 'smooth'
    window.addEventListener('message', handleEventComing)
    return () => {
      window.removeEventListener('message', handleEventComing)
      if (html) html.style.scrollBehavior = 'auto'
    }
  }, [])

  const { t, i18n } = useTranslation()

  const meta = useMemo(() => i18nLanguage.meta.documentation, [i18nLanguage])

  //* Check if metaTags.title is available, if not, use the default title
  //* Remove the pipe character and the rest of the string after it
  const title = metaTags.title
    ? t(meta.title, { detail: metaTags.title })
    : t(meta.title).split('|')[0]

  return (
    <>
      <Helmet prioritizeSeoTags htmlAttributes={{ lang: i18n.language }}>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={t(meta.description)} />
        <meta property="og:description" content={t(meta.ogDescription)} />
        <meta property="og:image" content="https://developers.gamescale.io/gsd-open-graph.png" />
        <meta
          property="og:image:secure_url"
          content="https://developers.gamescale.io/gsd-open-graph.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        {metaTags?.meta.map((item) => (
          <meta key={item.name} name={item.name} content={item.content} />
        ))}
      </Helmet>
      <DocumentsProvider>
        <RenderDocumentPage />
      </DocumentsProvider>
    </>
  )
}

export default Documents
