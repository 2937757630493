export const support = {
  noticeList: {
    table: {
      header: {
        title: 'Title',
        date: 'Date',
      },
    },
  },
  newsDetail: {
    toastMessage: 'Link copied',
  },
}
