export const header = {
  meta: {
    title: "GameScale | Game development solution with NEXON's expertise",
    description:
      "GameScale offers A-Z solutions built on NEXON's know-how across all stages of game development including game security, marketing, and operations.",
  },
  nav: {
    service: 'Service',
    serviceOverview: 'Service List',
    document: 'Documentation',
    support: 'Support',
    announcements: 'Announcements',
    news: 'News',
  },
  searchPlaceholder: 'Search',
  login: 'Sign in',
  logout: 'Sign out',
}
