import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'

import { Button } from '@/components/button'
import { ArrowBackIcon, ArrowSubMenuIcon } from '@/components/icons'
import { i18nLanguage } from '@/i18n'
import { HeaderMenuMobileItemLv3 } from '@/layout/header/menu-mobile/menu-mobile-item-lv3'
import { HeaderMenuItemLv2 } from '@/layout/types'
import { useHeaderContext } from '@/providers/header-provider'

import classes from './menu-mobile-item-lv2.module.scss'

type HeaderMenuMobileItemLv2Props = {
  serviceCategory: HeaderMenuItemLv2
}

export const HeaderMenuMobileItemLv2 = ({ serviceCategory }: HeaderMenuMobileItemLv2Props) => {
  const navigate = useNavigate()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const {
    state: { activeSubmenu },
    actions,
  } = useHeaderContext()
  const { label, link, services, id } = serviceCategory

  const hasServices = services && services.length > 0
  const showMenuListService = activeSubmenu !== null && activeSubmenu === id

  const handleClickHeaderItemLv2 = () => {
    if (hasServices && id) return actions.setSubmenuId({ submenuId: id })

    if (link) {
      actions.closeMenu()
      navigate(`/${language}/${link}`)
    }
  }

  return (
    <div className={classes['header-mobile-menu-item-lv2-wrapper']}>
      <Button
        data-testid="header-mobile-menu-lv2-item"
        className={classes['header-mobile-menu-item-lv2']}
        onClick={handleClickHeaderItemLv2}
      >
        <span className={classes['item-label']}>{t(label)}</span>
        <div
          data-testid="header-mobile-menu-lv2-item-more-icon"
          className={clsx(classes['more-btn'], { [classes['show']]: hasServices })}
        >
          <ArrowSubMenuIcon />
        </div>
      </Button>
      <div
        data-testid="header-mobile-list-menu-lv3"
        className={clsx(classes['list-children'], {
          [classes['show']]: showMenuListService,
        })}
      >
        <Button
          data-testid="header-mobile-menu-back-btn"
          className={classes['back-btn']}
          onClick={actions.resetSubmenuId}
        >
          <div className={classes['arrow-back-icon']}>
            <ArrowBackIcon />
          </div>
          {t(i18nLanguage.common.button.back)}
        </Button>

        {hasServices &&
          services.map((service) => <HeaderMenuMobileItemLv3 key={service.id} service={service} />)}
      </div>
    </div>
  )
}
