export function LogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="174"
      height="36"
      fill="none"
      viewBox="0 0 174 36"
    >
      <g clipPath="url(#clip0_25_9437)">
        <path
          fill="#fff"
          d="M123.484 7.334c.676-.683 1.492-1.13 2.447-1.13l4.581-.012V3.284l-4.581.014c-1.773 0-3.287.632-4.543 1.907-1.255 1.275-1.882 2.807-1.882 4.604 0 1.797.627 3.332 1.882 4.603 1.258 1.272 2.77 1.827 4.543 1.827l4.581-.003v-2.877l-4.581.004c-.954 0-1.773-.386-2.447-1.073a3.41 3.41 0 01-1.011-2.48c0-.967.335-1.793 1.011-2.478v.002zM138.271 3.286l-5.014 12.957h3.224c.976-2.602 2.197-5.573 3.133-8.19.934 2.617 2.155 5.588 3.133 8.19h3.224L140.91 3.286h-2.639zM70.585 16.242V3.273l3.418.012 3.627 6.806 3.465-6.821h3.418v12.972h-2.849v-7.57l-2.24 4.358-3.59.004-2.403-4.363v7.57h-2.848.002zM92.33 13.357V11.19h6.32V8.33h-6.32V6.154h8.115l-.006-2.871-10.957-.004v12.962h10.957l.006-2.873-8.116-.012zM165.685 13.357V11.19h6.32V8.33h-6.32V6.154h8.115l-.005-2.871-10.957-.004v12.962h10.957l.005-2.873-8.115-.012zM159.932 16.242h-10.707V3.294h2.853v10.07h7.854V16.242zM45.394 6.185h6.25V3.286h-6.25a6.48 6.48 0 100 12.958h6.25v-7.91h-4.54l-1.794 2.77h3.54v2.242h-3.458a3.562 3.562 0 01-3.557-3.579 3.56 3.56 0 013.557-3.578l.002-.004zM112.801 8.33h-4.825a1.096 1.096 0 01-1.099-1.094c0-.604.491-1.093 1.099-1.093h7.797V3.284h-7.797c-2.192 0-3.97 1.77-3.97 3.952 0 2.183 1.778 3.942 3.97 3.942h4.825c.606 0 1.098.489 1.098 1.093s-.49 1.093-1.098 1.093h-7.798v2.877h7.798a3.968 3.968 0 003.97-3.961 3.96 3.96 0 00-3.97-3.952v.002zM62.457 3.286h-2.682L54.76 16.243h3.223l3.132-8.296 3.133 8.296h3.224L62.457 3.286z"
        ></path>
        <path
          fill="url(#paint0_linear_25_9437)"
          d="M30.307 19.06l-8.293 4.285v9.833l8.293-4.242V19.06z"
        ></path>
        <path
          fill="#fff"
          d="M22.79 14.988l-8.448 4.076 7.67 4.28 8.294-4.285-7.518-4.07h.001z"
        ></path>
        <path
          fill="url(#paint1_linear_25_9437)"
          d="M10.543 23.495l-.007 12.119 10.716-6.017-10.709-6.102z"
        ></path>
        <path
          fill="url(#paint2_linear_25_9437)"
          d="M.8 9.047L.857 30.31l9.68 5.304.011-21.063L.8 9.047z"
        ></path>
        <path
          fill="#fff"
          d="M18.412.345L.8 9.047l9.748 5.505 17.335-8.78-9.47-5.427zM101.8 22.726h-1.249a4.07 4.07 0 00-4.064 4.066v1.72a4.069 4.069 0 004.064 4.064h1.249a4.069 4.069 0 004.064-4.066v-1.72a4.07 4.07 0 00-4.064-4.065v.001zm2.812 5.786a2.814 2.814 0 01-2.812 2.812h-1.249a2.814 2.814 0 01-2.811-2.812v-1.72c0-1.55 1.26-2.812 2.811-2.812h1.249a2.814 2.814 0 012.812 2.812v1.72zM70.503 22.908l-2.976 7.712-2.974-7.712h-1.478l3.757 9.489h1.428l3.722-9.489h-1.48zM59.955 24.143l-.004-1.235h-7.63v9.489h7.63l.004-1.228h-6.383V28.19h4.57v-1.069h-4.57v-2.98h6.383zM81.16 28.191v-1.069h-4.57v-2.98h6.382l-.003-1.234h-7.63v9.489h7.63l.004-1.228h-6.384V28.19h4.57zM128.531 24.143l-.004-1.235h-7.63v9.489h7.63l.004-1.228h-6.383V28.19h4.57v-1.069h-4.57v-2.98h6.383zM86.717 22.908v9.476l7.38.01.004-1.225-6.134-.01v-8.25h-1.25zM152.165 29.7a2.698 2.698 0 00-2.695-2.695h-3.04a1.429 1.429 0 010-2.855h5.066v-1.242h-5.18a2.671 2.671 0 00-2.669 2.669 2.671 2.671 0 002.669 2.668h3.086a1.453 1.453 0 010 2.904h-5.143v1.244h5.213a2.697 2.697 0 002.694-2.695l-.001.002zM49.345 27.653a4.751 4.751 0 00-4.747-4.745h-3.831v9.485h3.831a4.749 4.749 0 004.747-4.74zm-7.326-3.512h2.58a3.516 3.516 0 013.511 3.512 3.516 3.516 0 01-3.512 3.512h-2.58v-7.024zM117.537 26.092a3.186 3.186 0 00-3.183-3.182h-5.172v9.488h1.253v-3.122h3.919a3.187 3.187 0 003.183-3.182v-.002zm-7.102-1.949h3.919c1.075 0 1.95.875 1.95 1.95a1.952 1.952 0 01-1.95 1.948h-3.919v-3.898zM139.002 32.395h1.326l-2.449-3.124.682-.275a3.15 3.15 0 001.987-2.927c0-1.742-1.429-3.159-3.182-3.159h-5.173v9.487h1.252V29.23h3.076l2.481 3.167v-.002zm-5.557-8.254h3.921a1.94 1.94 0 011.949 1.927c0 1.061-.875 1.926-1.949 1.926h-3.921v-3.853z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_25_9437"
          x1="26.35"
          x2="26.084"
          y1="33.572"
          y2="23.193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8C8C8"></stop>
          <stop offset="1" stopColor="#8A8A8A"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_25_9437"
          x1="8.412"
          x2="21.568"
          y1="27.695"
          y2="36.331"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.19" stopColor="#5C5C5C"></stop>
          <stop offset="1" stopColor="#DADADA" stopOpacity="0.85"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_25_9437"
          x1="0.377"
          x2="8.15"
          y1="45.095"
          y2="11.649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8C8C8"></stop>
          <stop offset="1" stopColor="#8A8A8A"></stop>
        </linearGradient>
        <clipPath id="clip0_25_9437">
          <path fill="#fff" d="M0 0H173V35.271H0z" transform="translate(.8 .345)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
